import { Divider, Flex } from "@chakra-ui/react";
import React from "react";
import { ChatToArticleOnAssistantMenu } from "./ChatToArticleOnAssistantMenu";
import { ToggleOnlyCurrentChatBttn } from "./ToggleOnlyCurrentChatBttn";

export function ChatControls({ length }: { length: number }) {
  return (
    <Flex pb="8px" align="center">
      <Flex bg="#FBFBFB" align="center" w="100%" borderRadius="md" gap="4">
        <ChatToArticleOnAssistantMenu />
        <Divider
          orientation="vertical"
          h="20px"
          borderColor="black"
          display={length >= 2 ? "flex" : "none"}
        />
        <ToggleOnlyCurrentChatBttn />
      </Flex>
    </Flex>
  );
}
