import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Link,
  Switch,
  Text,
  useToast,
  UseToastOptions,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  MdOutlineArrowBack,
  MdOutlineEdit,
  MdOutlinePublish,
} from "react-icons/md";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import { useAuth, UserWithEmail } from "../../Auth/AuthProvider";

import { doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import {
  aiModulesCollection,
  getModuleInstructionDoc,
  getModulePresentationDoc,
} from "src/db/aiModule";
import EditableText from "../General/EditableText";
import Layout from "../Layout";
import AdminControls from "./AdminControls";
import { createNewModule, releaseNewModuleVersion } from "./AiModuleTypes";
import {
  InputCombinationWithMenu,
  InputCombinationWithTooltip,
  ModuleEditorTooltipWithIcon,
} from "./InputCombinationWithTooltip";

const DEFAULT_VARIABLE = "user_input";

interface AiModuleFormData {
  title: string;
  description: string;
  systemMessage: string;
  userMessage?: string;
  temperature: number;
  variableDescription?: string;
  variableIsOptional?: boolean;
}

const validateModuleData = (data: AiModuleFormData, showVariable: boolean) => {
  const missingFields: string[] = [];
  const otherMessages: string[] = [];

  if (!data.title) missingFields.push("Name");
  if (!data.description) missingFields.push("Description");
  if (!data.systemMessage) missingFields.push("Role, objective & instructions");
  // if (!data.userMessage) missingFields.push("User message");

  if (showVariable) {
    if (!data.variableDescription) missingFields.push("User input description");
    if (data.variableIsOptional === undefined)
      otherMessages.push("Set if user input can be skipped");
    if (!data.userMessage?.includes(`{{${DEFAULT_VARIABLE}}}`))
      otherMessages.push(
        `User message must include {{${DEFAULT_VARIABLE}}} to show where the user input will be inserted`
      );
  }

  return { missingFields, otherMessages };
};

const handleCreateCustomAiModule = async (
  moduleData: AiModuleFormData,
  userId: string,
  userEmail: string,
  toast,
  history,
  showVariable: boolean
) => {
  console.log("moduleData: ", moduleData);
  const { missingFields, otherMessages } = validateModuleData(
    moduleData,
    showVariable
  );

  if (missingFields.length > 0) {
    // alert(`Please fill in the following fields: ${missingFields.join(", ")}`);
    toast({
      title: "Please fill in the following fields",
      description: missingFields.join(", "),
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
    return;
  }
  if (otherMessages.length > 0) {
    otherMessages.forEach((message) => {
      toast({
        title: "",
        description: message,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    });
    return;
  }
  await createNewModule({
    title: moduleData.title,
    description: moduleData.description,
    systemMessage: moduleData.systemMessage,
    userMessage: moduleData.userMessage || "",
    temperature: moduleData.temperature,
    variable: showVariable ? DEFAULT_VARIABLE : undefined,
    variableDescription: showVariable
      ? moduleData.variableDescription
      : undefined,
    variableIsOptional: showVariable
      ? moduleData.variableIsOptional
      : undefined,
    userId,
    userEmail,
  });

  toast({
    title: "Module created",
    description: "Your module has been created successfully",
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top-right",
  });

  history.push("/user?tab=custom-ai");
};

const handleReleaseNewModuleVersion = async (
  moduleId,
  moduleData: AiModuleFormData,
  toast,
  history,
  userId: string,
  showVariable: boolean
) => {
  console.log("moduleData: ", moduleData);
  const { missingFields, otherMessages } = validateModuleData(
    moduleData,
    showVariable
  );

  if (missingFields.length > 0) {
    // alert(`Please fill in the following fields: ${missingFields.join(", ")}`);
    toast({
      title: "Please fill in the following fields",
      description: missingFields.join(", "),
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
    return;
  }
  if (otherMessages.length > 0) {
    otherMessages.forEach((message) => {
      toast({
        title: "",
        description: message,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    });
    return;
  }
  await releaseNewModuleVersion({
    moduleId,
    title: moduleData.title,
    description: moduleData.description,
    systemMessage: moduleData.systemMessage,
    userMessage: moduleData.userMessage || "",
    temperature: moduleData.temperature,
    variable: showVariable ? DEFAULT_VARIABLE : undefined,
    variableDescription: showVariable
      ? moduleData.variableDescription
      : undefined,
    variableIsOptional: showVariable
      ? moduleData.variableIsOptional
      : undefined,
    userId,
  });

  toast({
    title: "Module version released",
    description: "Your module version has been released successfully",
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top-right",
  });

  history.push("/user?tab=custom-ai");
};

function MainControls(props: {
  setModuleData: React.Dispatch<React.SetStateAction<AiModuleFormData>>;
  moduleData: AiModuleFormData;
}) {
  return (
    <>
      {/* <InputCombinationWithTooltip
        title="Title"
        tooltipText="Used only for display and doesn't affect how the AI module works. Choose something descriptive."
        width="100%"
        inputType="input"
        onChange={(value) => {
          props.setModuleData({ ...props.moduleData, title: value.toString() });
        }}
        defaultValue={props.moduleData.title}
        inputProps={{
          placeholder: "Financial analysis...",
        }}
      /> */}
      <InputCombinationWithTooltip
        title="Description"
        tooltipText="Like the title, this is only for display and doesn’t impact how the AI module works."
        width="100%"
        inputType="textarea"
        onChange={(value) => {
          props.setModuleData({
            ...props.moduleData,
            description: value.toString(),
          });
        }}
        defaultValue={props.moduleData.description}
        inputProps={{
          placeholder: "Performs analysis on financial documents...",
        }}
      />
      <InputCombinationWithTooltip
        title="Role, objective & instructions"
        tooltipText="The main instruction for the AI module on what it should do, why, and how. Sets the overall tone and context"
        width="100%"
        inputType="textarea"
        onChange={(value) => {
          props.setModuleData({
            ...props.moduleData,
            systemMessage: value.toString(),
          });
        }}
        defaultValue={props.moduleData.systemMessage}
        inputProps={{
          height: "300px",
          placeholder: "Act as a financial advisor...",
        }}
      />
      <InputCombinationWithTooltip
        title="User message"
        tooltipText="Enter a specific question or instruction for the AI to respond to each time you use this AI module. In most cases, it's fine to leave this blank."
        width="100%"
        inputType="textarea"
        onChange={(value) => {
          props.setModuleData({
            ...props.moduleData,
            userMessage: value.toString(),
          });
        }}
        defaultValue={props.moduleData.userMessage}
        inputProps={{
          placeholder: "User message text",
          height: "70px",
        }}
      />
      <InputCombinationWithTooltip
        title="Temperature"
        tooltipText="Controls the AI’s creativity. Lower values (like 0) make responses more focused and predictable, while higher values add variety. For most tasks, especially data-focused ones, 0 is recommended. If unsure, leave it at 0."
        width="100%"
        inputType="numberInput"
        onChange={(value) => {
          props.setModuleData({
            ...props.moduleData,
            temperature: Number(value),
          });
        }}
        defaultValue={props.moduleData.temperature}
        inputProps={{
          inputMode: "numeric",
          placeholder: "0.0",
          min: 0,
          max: 2,
        }}
      />
    </>
  );
}

function VariableControls(props: {
  moduleData: AiModuleFormData;
  setModuleData: (moduleData: AiModuleFormData) => void;
  showVariable: boolean;
}) {
  return (
    <Flex
      direction={"column"}
      gap={"10px"}
      display={props.showVariable ? "flex" : "none"}
    >
      <HStack>
        {/* <InputCombinationWithTooltip
          title="Variable"
          width="100%"
          inputType="input"
          onChange={(value) => {
            props.setModuleData({
              ...props.moduleData,
              variable: value.toString(),
            });
          }}
          tooltipText={`Variable is used in the User message, and can be set at module invocation by the user. For example, if you add {{name}} in the User message, and set "name" in this field, when invoking the module the user will be prompted to enter a value for "name".`}
          defaultValue={props.moduleData.variable || ""}
          inputProps={{
            placeholder: "any_variable",
          }}
        /> */}
        <InputCombinationWithTooltip
          title="User input description"
          tooltipText="Describe what the user can input. For example, if you set 'The name of the company', the user will be asked 'Please enter the name of the company'."
          width="100%"
          inputType="input"
          onChange={(value) => {
            props.setModuleData({
              ...props.moduleData,
              variableDescription: value.toString(),
            });
          }}
          defaultValue={props.moduleData.variableDescription || ""}
          inputProps={{
            placeholder: "The name of the company",
          }}
        />
        <InputCombinationWithMenu
          title="Input can be skipped?"
          width="100%"
          onChange={(value) => {
            props.setModuleData({
              ...props.moduleData,
              variableIsOptional: value === "Yes",
            });
          }}
          tooltipText="If yes, user can skip input when invoking the module. In other words, the input becomes optional."
          options={["Yes", "No"]}
          defaultValue={
            props.moduleData.variableIsOptional === true
              ? "Yes"
              : props.moduleData.variableIsOptional === false
              ? "No"
              : ""
          }
        />
      </HStack>
    </Flex>
  );
}

function VariableToggle(props: {
  showVariable: boolean;
  setShowVariable: (showVariable: boolean) => void;
  onToggle?: (showVariable: boolean) => void;
}) {
  return (
    <Flex gap={"10px"} align={"center"}>
      <Text fontSize="10px" fontWeight="500">
        {"No user input"}
      </Text>
      <Switch
        isChecked={props.showVariable}
        onChange={() => {
          props.setShowVariable(!props.showVariable);
          props.onToggle?.(!props.showVariable);
        }}
      />
      <Text fontSize="10px" fontWeight="500">
        {"User input"}
      </Text>
      <ModuleEditorTooltipWithIcon
        label={
          "If enabled, user can enter additional information when the module runs. This information will be inserted into the user message at the position of {{user_input}}."
        }
      />
    </Flex>
  );
}

function Header(props: {
  moduleId: string;
  versionId: string;
  moduleData: AiModuleFormData;
  setModuleData: (moduleData: AiModuleFormData) => void;
  showVariable: boolean;
  currentUser: UserWithEmail;
  isAiModulesAdmin: boolean;
  toast: (options?: UseToastOptions) => void;
  history: History;
}) {
  return (
    <Flex gap={2} direction="row" align="center" justify="space-between">
      <HStack gap={0}>
        <Link as={ReactRouterLink} to="/user?tab=custom-ai">
          <MdOutlineArrowBack color="primary" fontSize="18px" />
        </Link>
        <Flex h="14px" align={"center"}>
          {/* <Text
            fontWeight="medium"
            fontSize="14px"
            border="none"
            pl="8px"
            w="fit-content"
          >
            {props.moduleData.title}
          </Text> */}
          <EditableText
            text={props.moduleData.title}
            submitHandler={(value) => {
              props.setModuleData({ ...props.moduleData, title: value });
            }}
            inputProps={{
              fontSize: "14px",
              fontWeight: "medium",
              border: "none",
              pl: "8px",
              w: "fit-content",
            }}
            placeholder={"Name your module..."}
          />
          <Icon as={MdOutlineEdit} fontSize="14px" />
        </Flex>
      </HStack>
      <Flex gap="10px">
        {!props.moduleId && props.isAiModulesAdmin && (
          <Link href="/multistep-ai-module-editor">
            <Text color="#727272" fontSize="12px">
              Switch to multistep
            </Text>
          </Link>
        )}
        <Flex
          color="#727272"
          fontSize="12px"
          align="center"
          cursor="pointer"
          onMouseDown={async () => {
            // if showVariable is false, set all related fields to undefined
            let newModuleData: AiModuleFormData = props.moduleData;

            if (!props.showVariable) {
              newModuleData = {
                ...props.moduleData,
                variableDescription: undefined,
                variableIsOptional: undefined,
              };
              props.setModuleData(newModuleData);
            }

            if (!(props.moduleId && props.versionId)) {
              await handleCreateCustomAiModule(
                newModuleData,
                props.currentUser.uid,
                props.currentUser.email,
                props.toast,
                props.history,
                props.showVariable
              );
            } else {
              await handleReleaseNewModuleVersion(
                props.moduleId,
                newModuleData,
                props.toast,
                props.history,
                props.currentUser.uid,
                props.showVariable
              );
            }
          }}
        >
          <MdOutlinePublish fontSize="16px" />
          <Text>Publish</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export const AiModuleEditor = () => {
  // retrieve query params
  const { moduleId, versionId } = useParams<{ moduleId: string }>();

  const [moduleData, setModuleData] = useState<AiModuleFormData>({
    title: "",
    description: "",
    systemMessage: "",
    userMessage: "",
    temperature: 0,
  });
  const [showVariable, setShowVariable] = useState(false);
  const { currentUser, isAiModulesAdmin } = useAuth();
  const toast = useToast();
  const history = useHistory();

  /**
   * Fetch the module data from the database
   */
  useEffect(() => {
    if (!moduleId && !versionId) return;

    // retrieve module data from the database
    const redirectToActiveVersion = async () => {
      const moduleRef = doc(aiModulesCollection, moduleId);
      const moduleDocData = await getDoc(moduleRef);
      if (!moduleDocData.exists()) {
        // redirect to the home page
        history.push("/");
      }

      const moduleData = moduleDocData.data();
      history.push(
        `/ai-module-editor/${moduleId}/${moduleData?.activeVersionId}`
      );
    };

    if (moduleId && !versionId) {
      redirectToActiveVersion();
    }

    if (moduleId && versionId) {
      const loadModuleData = async () => {
        // load data for the specific version directly
        const instructionRef = getModuleInstructionDoc(moduleId, versionId);
        const instructionDoc = await getDoc(instructionRef);
        const instructionData = instructionDoc.data();
        const presentationRef = getModulePresentationDoc(moduleId, versionId);
        const presentationDoc = await getDoc(presentationRef);
        const presentationData = presentationDoc.data();

        const variable =
          Object.keys(presentationData?.variables || {})[0] || undefined;

        if (!variable) {
          setShowVariable(false);
        } else {
          setShowVariable(true);
        }

        setModuleData({
          title: presentationData?.title || "",
          description: presentationData?.description || "",
          systemMessage: instructionData?.systemMessage || "",
          userMessage: presentationData?.userMessage || "",
          temperature: instructionData?.temperature || 0,
          variableDescription: variable
            ? presentationData?.variables?.[variable]?.description
            : undefined,
          variableIsOptional: variable
            ? presentationData?.variables?.[variable]?.optional
            : undefined,
        });
      };

      loadModuleData();
    }
  }, [moduleId, versionId]);

  return (
    <Layout rest={undefined} hideAssistant={true}>
      <Flex flexDir="column" w="60%">
        <Box py="21px">
          <Header
            moduleId={moduleId}
            versionId={versionId}
            moduleData={moduleData}
            setModuleData={setModuleData}
            showVariable={showVariable}
            currentUser={currentUser}
            isAiModulesAdmin={isAiModulesAdmin}
            toast={toast}
            history={history}
          />
        </Box>
        <Box pb="24px">
          <Text
            color="hsla(0, 0%, 10%, 0.54)"
            fontFamily="Inter"
            fontSize="12px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
            sx={{
              "& a": {
                color: "#08B2E3",
                fontWeight: "700",
                _hover: {
                  color: "#08B2E3",
                  textDecoration: "underline",
                },
              },
            }}
          >
            Welcome to the Module Builder! Need help? Visit our{" "}
            <Link href="/help" target="_blank">
              Help Center
            </Link>{" "}
            for guidance and support.
          </Text>
        </Box>
        <Flex gap={6} flexDir="column">
          <MainControls setModuleData={setModuleData} moduleData={moduleData} />
          <Divider borderColor="black" />
          <VariableToggle
            showVariable={showVariable}
            setShowVariable={setShowVariable}
            onToggle={(showVariable) => {
              if (showVariable && !moduleData.userMessage) {
                setModuleData({
                  ...moduleData,
                  userMessage: `{{${DEFAULT_VARIABLE}}}`,
                });
                return;
              }
              if (
                !showVariable &&
                moduleData.userMessage === `{{${DEFAULT_VARIABLE}}}`
              ) {
                setModuleData({
                  ...moduleData,
                  userMessage: "",
                });
              }
            }}
          />
          <VariableControls
            moduleData={moduleData}
            setModuleData={setModuleData}
            showVariable={showVariable}
          />
          <Divider borderColor="black" />
          {isAiModulesAdmin && <AdminControls />}
          {/* <InputCombinationWithAddIcon
            title="User’s email adresses"
            tooltipText="User’s email adresses"
            placeholder="email@email.com"
            width="50%"
            inputType="input"
            onChange={(value: string) => {
              setEmails([...emails, value]);
            }}
          /> */}
        </Flex>
        {/* <List spacing={3} mt={2}>
          {emails.map((email, index) => (
            <ListItem key={index}>
              <HStack>
                <Text
                  display="inline-block"
                  fontSize="10px"
                  color="secondaryGrey"
                >
                  {email}
                </Text>
                <ListIcon
                  fontSize="13px"
                  as={IoMdCheckmark}
                  color="green.500"
                />
              </HStack>
            </ListItem>
          ))}
        </List> */}
      </Flex>
    </Layout>
  );
};
