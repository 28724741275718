import { TooltipProps } from "@chakra-ui/react";

import { Tooltip } from "@chakra-ui/react";
import React from "react";

interface ModuleEditorTooltipProps extends Omit<TooltipProps, "children"> {
  children: React.ReactNode;
}

const ModuleEditorTooltip = ({
  children,
  ...tooltipProps
}: ModuleEditorTooltipProps) => {
  return (
    <Tooltip
      ml={2}
      hasArrow
      bg="hsla(0, 0%, 95%, 1)"
      color="hsla(0, 0%, 10%, 1)"
      padding="12px"
      borderRadius="4px"
      boxShadow="none"
      fontSize="12px"
      border="none"
      fontFamily="Inter"
      {...tooltipProps}
    >
      {children}
    </Tooltip>
  );
};

export default ModuleEditorTooltip;
