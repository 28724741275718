export const ViewMode: {
  CHAT: "CHAT";
  HISTORY: "HISTORY";
  TEMPLATE: "TEMPLATE";
  CUSTOMAI: "CUSTOMAI";
} = {
  CHAT: "CHAT",
  HISTORY: "HISTORY",
  TEMPLATE: "TEMPLATE",
  CUSTOMAI: "CUSTOMAI",
};

export type ViewMode = (typeof ViewMode)[keyof typeof ViewMode];

export const TEMPLATE_MODE: {
  LIST: "LIST";
  VIEW: "VIEW";
  CREATE: "CREATE";
} = {
  LIST: "LIST",
  VIEW: "VIEW",
  CREATE: "CREATE",
};
