import React, { useEffect, useRef } from "react";
import { useAssistantContext } from "../../Context/AssistantContext";
import { useTemplateContext } from "../../Context/TemplateContext";
import { AddToTemplate } from "../assistant/ContextMenu/AddToTemplate";
import PermissionWrapper from "../HigherOrderComponents/PermissionWrapper";
import { SourceArticle } from "../HigherOrderComponents/withSaveSelectionToInsights";
import ModuleEditorTooltip from "../ModuleEditorTooltip";
import "./SaveToInsightsFloatingWindow.css";

/**
 * Floating window that appears when user selects text.
 */
const SaveToInsightsFloatingWindow = ({
  text,
  position,
  onClickAway,
  onButtonClick,
  sourceArticles,
}: {
  text: string;
  position: { x: number; y: number };
  onClickAway: () => void;
  onButtonClick: (args: {
    text: string;
    sourceArticles?: SourceArticle[];
    conversationId: string;
  }) => void;
  sourceArticles: SourceArticle[] | undefined;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { conversationId } = useAssistantContext();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickAway();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickAway]);

  const handleClick = () => {
    onButtonClick({ text, sourceArticles, conversationId });
  };
  const { templateList } = useTemplateContext();

  // console.log("text :>> ", text);
  return (
    <div
      className="selection-window"
      ref={ref}
      style={{
        position: "fixed",
        left: position.x,
        top: position.y,
      }}
    >
      <PermissionWrapper>
        {(hasEditPermission) => (
          <button onClick={handleClick} disabled={!hasEditPermission}>
            <ModuleEditorTooltip
              label={
                !hasEditPermission &&
                "You do not have permission to save insights in this project"
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#191d30",
                  padding: "3px 8px",
                  borderRadius: templateList.length > 0 ? "4px 0 0 4px" : "4px",
                  fontWeight: "bold",
                  maxWidth: "97px",
                  maxHeight: "20px",
                  cursor: hasEditPermission ? "pointer" : "not-allowed",
                }}
              >
                Save insight
              </div>
            </ModuleEditorTooltip>
          </button>
        )}
      </PermissionWrapper>
      <AddToTemplate
        highlightedText={text}
        onClickAway={onClickAway}
        sourceArticles={sourceArticles}
      />
    </div>
  );
};

export default SaveToInsightsFloatingWindow;
