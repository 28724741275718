import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  InputGroup,
} from "@chakra-ui/react";
import React from "react";
import { FiSend } from "react-icons/fi";
import ReactTextareaAutosize from "react-textarea-autosize";
import { useAutoFocus } from "src/hooks/useAutoFocus";
import { useKeyboardShortcut } from "src/hooks/useKeyboardShortcut";
import { QUERY_PARAMS, useQueryParam } from "src/utils/queryParams";
import { useAssistantContext } from "../../Context/AssistantContext";
import HeaderBoxMenuIcon from "../Icons/HeaderBoxMenuIcon";
import { StopGeneration } from "./StopGeneration";

function TextInput({
  inputRef,
  assistantDisabled,
  handleSendClick,
  inputFieldPlaceholderText = "Ask the assistant anything...",
}: {
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  assistantDisabled: boolean;
  handleSendClick: () => Promise<void>;
  inputFieldPlaceholderText?: string;
}) {
  const { inputValue: contextInputValue, setInputValue: setContextInputValue } =
    useAssistantContext();
  const [localInputValue, setLocalInputValue] =
    React.useState(contextInputValue);

  // Update local value when context value changes (e.g. when cleared after sending)
  React.useEffect(() => {
    setLocalInputValue(contextInputValue);
  }, [contextInputValue]);

  // Debounce updates to context to prevent excessive re-renders
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setContextInputValue(localInputValue);
    }, 100);

    return () => clearTimeout(timeout);
  }, [localInputValue, setContextInputValue]);

  useAutoFocus("assistant-chat-input", [], {
    delay: 50,
  });

  useKeyboardShortcut(
    [
      {
        key: "Enter",
        action: async () => {
          await handleSendClick();
        },
        description: "Send message",
      },
    ],
    {
      targetElementIds: ["assistant-chat-input"],
    }
  );

  return (
    <InputGroup id="chatBoxTour" w="full">
      <ReactTextareaAutosize
        id="assistant-chat-input"
        className="webkit-scrollbar-display-none"
        ref={inputRef}
        minRows={1}
        maxRows={3}
        style={{
          overflow: "auto",
          scrollbarWidth: "none",
          backgroundColor: "#F6F6F6",
          fontSize: "14px",
          paddingRight: "",
          width: "100%",
          border: "none",
          padding: "8px 0px 8px 16px",
          borderRadius: "12px",
          fontFamily: "inherit",
          lineHeight: "20px",
          outline: "none",
          resize: "none",
        }}
        placeholder={assistantDisabled ? "" : inputFieldPlaceholderText}
        onChange={(e) => setLocalInputValue(e.target.value)}
        value={localInputValue}
        disabled={assistantDisabled}
      />
    </InputGroup>
  );
}

function SendMessageButton({ assistantDisabled, handleSendClick, hasInput }) {
  return (
    <IconButton
      color="#fff"
      borderRadius={"4px"}
      bg={assistantDisabled ? "#9C9C9C" : hasInput ? "primary" : "transparent"}
      aria-label="Send message"
      icon={
        <FiSend
          color={hasInput ? "#fff" : "#9C9C9C"}
          style={{ transition: "color 0.2s ease" }}
        />
      }
      onClick={assistantDisabled ? () => {} : handleSendClick}
      cursor={
        assistantDisabled ? "not-allowed" : hasInput ? "pointer" : "default"
      }
      transition="all 0.15s ease"
      _hover={{
        bg: assistantDisabled
          ? "#9C9C9C"
          : hasInput
          ? "primary"
          : "transparent",
      }}
    />
  );
}

type InputFieldButtonsProps = {
  assistantIsGenerating?: boolean;
  assistantDisabled: boolean;
  handleSendClick?: () => void;
  handleStopGeneration?: () => void;
  hasInput: boolean;
};

const InputFieldButtons: React.FC<InputFieldButtonsProps> = ({
  assistantIsGenerating,
  assistantDisabled,
  handleSendClick,
  handleStopGeneration,
  hasInput,
}) => {
  if (!handleSendClick) {
    return null;
  }

  if (assistantIsGenerating) {
    return <StopGeneration handleStopGeneration={handleStopGeneration} />;
  }

  return (
    <HStack w="fit-content" color="black" align="center" justify="center">
      <SendMessageButton
        assistantDisabled={assistantDisabled}
        handleSendClick={handleSendClick}
        hasInput={hasInput}
      />
    </HStack>
  );
};

type InputFieldProps = {
  assistantDisabled: boolean;
  assistantIsGenerating?: boolean;
  handleSendClick: () => Promise<void>;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  inputFieldPlaceholderText?: string;
};

const InputField: React.FC<InputFieldProps> = ({
  assistantDisabled,
  assistantIsGenerating,
  handleSendClick,
  inputRef,
  inputFieldPlaceholderText,
}) => {
  if (assistantIsGenerating) {
    return null;
  }

  return (
    <TextInput
      inputRef={inputRef}
      assistantDisabled={assistantDisabled}
      handleSendClick={handleSendClick}
      inputFieldPlaceholderText={inputFieldPlaceholderText}
    />
  );
};

const ModulesButton = () => {
  const [modulesDrawer, setModulesDrawer] = useQueryParam(
    QUERY_PARAMS.modulesDrawer
  );

  return (
    <Button
      onClick={() => setModulesDrawer(!modulesDrawer)}
      bgGradient="linear(to-r, #0F97D7, #6925FD)"
      color="white"
      opacity={0.87}
      _hover={{
        bgGradient: "linear(to-r, #0F97D7, #6925FD)",
        opacity: 0.9,
      }}
      h="40px"
      px="16px"
      borderRadius="4px"
      fontSize="12px"
      fontWeight="500"
      leftIcon={<HeaderBoxMenuIcon boxSize="8px" fill="white" />}
      display="flex"
      alignItems="center"
      gap="0px"
    >
      Modules
    </Button>
  );
};

type AssistantChatInputProps = {
  assistantDisabled: boolean;
  assistantIsGenerating?: boolean;
  handleSendClick: () => Promise<void>;
  handleStopGeneration?: () => void;
  inputFieldPlaceholderText?: string;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
};

const AssistantChatInput: React.FC<AssistantChatInputProps> = ({
  assistantDisabled,
  assistantIsGenerating,
  handleSendClick,
  handleStopGeneration,
  inputRef,
  inputFieldPlaceholderText,
}) => {
  const { inputValue } = useAssistantContext();

  return (
    <Box>
      <Flex gap="8px" align="center">
        <Flex
          flex="1"
          gap="8px"
          justify="space-between"
          align="center"
          bg="#F6F6F6"
          borderRadius="4px"
        >
          <Box flex="1">
            <InputField
              assistantDisabled={assistantDisabled}
              assistantIsGenerating={assistantIsGenerating}
              handleSendClick={handleSendClick}
              inputRef={inputRef}
              inputFieldPlaceholderText={inputFieldPlaceholderText}
            />
          </Box>
          <InputFieldButtons
            assistantIsGenerating={assistantIsGenerating}
            assistantDisabled={assistantDisabled}
            handleSendClick={handleSendClick}
            handleStopGeneration={handleStopGeneration}
            hasInput={inputValue.trim().length > 0}
          />
        </Flex>
        <ModulesButton />
      </Flex>
    </Box>
  );
};

export default AssistantChatInput;
