import {
  Box,
  Divider,
  Flex,
  Skeleton,
  SkeletonText,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { CustomPromptListGrid } from "./AiModuleBox";

export const AiModulesSectionSkeleton = () => {
  const skeletonCount = 6;

  const AiModuleBoxSkeleton = ({
    width = "170px",
    accentColor,
  }: {
    width?: string;
    accentColor?: string;
  }) => {
    return (
      <Box
        position="relative"
        _before={
          accentColor
            ? {
                content: '""',
                display: "block",
                position: "absolute",
                top: "0",
                left: "0",
                width: "4px",
                height: "100%",
                backgroundColor: accentColor,
                borderRadius: "4px 0 0 4px",
              }
            : undefined
        }
        bg="white"
        border="1px solid"
        borderColor="#e0e0e0"
        flex="1"
        height="57px"
        p="8px"
        pl={accentColor ? "12px" : "8px"}
        w={width}
        borderRadius="md"
      >
        <VStack align="start" spacing="1" w="100%">
          <Skeleton height="12px" width="60%" mb="3px" />
          <SkeletonText
            noOfLines={2}
            spacing="1"
            skeletonHeight="8px"
            width="100%"
          />
        </VStack>
      </Box>
    );
  };
  return (
    <Flex flexDir="column">
      <Flex flexDir="column">
        <Flex align="center" gap="6px">
          <Skeleton w="20px" h="15px" />
          <Skeleton w="100px" h="14px" />
        </Flex>
        <Box py="10px">
          <Divider orientation="horizontal" flex="1" />
        </Box>
      </Flex>
      <CustomPromptListGrid>
        {Array(skeletonCount)
          .fill(0)
          .map((_, index) => (
            <AiModuleBoxSkeleton key={index} />
          ))}
      </CustomPromptListGrid>
    </Flex>
  );
};
