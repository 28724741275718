import {
  AiModuleInvocationMessageData,
  MessageData,
  SimpleChatMessageData,
  TemplateMessageData,
  WidgetMessageData,
} from "./conversation";

export function isSimpleChatMessage(
  message: MessageData
): message is SimpleChatMessageData {
  return message.type === "chat";
}

export function isWidgetMessage(
  message: MessageData
): message is WidgetMessageData {
  return message.type === "widget";
}

export function isAiModuleInvocationMessage(
  message: MessageData
): message is AiModuleInvocationMessageData {
  return message.type === "aiModuleInvocation";
}

export function isTemplateMessage(
  message: MessageData
): message is TemplateMessageData {
  return message.type === "template";
}
