import {
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { MdControlPointDuplicate, MdMoreHoriz } from "react-icons/md";
import { RiDeleteBin2Line } from "react-icons/ri";
import { useAuth } from "src/Auth/AuthProvider";
import { useProjectContext } from "src/Context/ProjectContext";
import { useUserNameAndPhotoUrl } from "src/db/project";
import { v4 as uuidv4 } from "uuid";
import { templateVersionOutdated } from "../../../Context/OrganizerContext";
import { useTemplateContext } from "../../../Context/TemplateContext";
import { deleteTemplate } from "../../../db/organizer";
import { PrimaryDeleteAlert } from "../../Elements/DeleteAlert";
import EditableText from "../../General/EditableText";
import TemplateIconComponent from "../../Icons/TemplateIconComponent";
import { getCustomizationData } from "../../Utils/customizationUtils";
import { formatDate } from "../../Utils/dateutils";

const customization = getCustomizationData();

const CreatedBy = ({ createdBy }: { createdBy: string }) => {
  const { data: authorData, isLoading: authorLoading } =
    useUserNameAndPhotoUrl(createdBy);
  if (authorLoading || !authorData?.data) {
    return null;
  }
  return (
    <Flex gap="8px">
      <Avatar boxSize="12px" src={authorData.data.photoURL} />
      <Text opacity="0.54" display="inline-block">
        Created by {authorData.data.displayName},
      </Text>
    </Flex>
  );
};

export function TemplateCard({
  handleTemplateSelection,
  updateTemplateName,
  projectId,
  template,
}) {
  const { currentUser } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { copyTemplateWithSections } = useTemplateContext();

  const startDuplicateTemplate = () => {
    const newTemplateId = uuidv4();
    copyTemplateWithSections(template.id, newTemplateId, true, currentUser.uid);
  };
  const { currentProjectData } = useProjectContext();
  const isSharedProject = currentProjectData?.data?.shared;

  return (
    <Box pos="relative">
      <Box
        bg="white"
        boxShadow="sm"
        p={4}
        rounded="sm"
        mb={2}
        cursor={
          templateVersionOutdated(template.version) ? "not-allowed" : "pointer"
        }
        onMouseDown={
          templateVersionOutdated(template.version)
            ? () => {}
            : (e: React.MouseEvent<HTMLElement>) => {
                if (
                  (e.target as HTMLElement).classList.value.includes(
                    "chakra-editable"
                  )
                ) {
                  return;
                }
                handleTemplateSelection(template.id);
              }
        }
      >
        <HStack mb="1">
          <Icon as={TemplateIconComponent} color="primary" boxSize={4} />
          <Box>
            <EditableText
              submitHandler={(value) => updateTemplateName(value, template.id)}
              text={template.name}
              inputProps={{
                fontSize: "xs",
                color: "primary",
                fontWeight: "medium",
                className: "editable-text",
              }}
            />
          </Box>
        </HStack>
        <Flex>
          <Flex fontSize="9px" fontStyle="italic">
            {isSharedProject && <CreatedBy createdBy={template.createdBy} />}{" "}
            <Text color="#191D30" opacity="0.5">
              {formatDate(template.createdAt)}
            </Text>
          </Flex>

          <Spacer />
          {templateVersionOutdated(template.version) && (
            <Text
              fontSize="x-small"
              color="red.500"
              fontStyle="italic"
              opacity="0.5"
            >
              This version of {customization.template.name.toLowerCase()} is no
              longer supported
            </Text>
          )}
        </Flex>
      </Box>
      <Menu>
        <MenuButton pos="absolute" top="5%" right="0" p="2" h="fit-content">
          <MdMoreHoriz color="#757575" />
        </MenuButton>
        <MenuList w="0%" p="0" border="none">
          <MenuItem
            gap={4}
            fontSize="12px"
            bg="primary"
            borderRadius="sm"
            color="white"
            onClick={() => onOpen()}
          >
            <RiDeleteBin2Line />
            Delete
          </MenuItem>
          <MenuItem
            gap={4}
            fontSize="12px"
            bg="primary"
            borderRadius="sm"
            color="white"
            onClick={() => {
              const newTemplateId = uuidv4();
              copyTemplateWithSections(
                template.id,
                newTemplateId,
                false,
                currentUser.uid
              );
            }}
          >
            <HiOutlineDocumentDuplicate />
            Duplicate
          </MenuItem>
          <MenuItem
            gap={4}
            fontSize="12px"
            bg="primary"
            borderRadius="sm"
            color="white"
            onClick={() => {
              startDuplicateTemplate();
            }}
          >
            <MdControlPointDuplicate />
            Duplicate with content
          </MenuItem>
        </MenuList>
      </Menu>
      <PrimaryDeleteAlert
        isOpen={isOpen}
        onClose={onClose}
        action={() => {
          deleteTemplate(template.id, projectId);
        }}
        body="Are you sure you want to delete this template?"
      />
    </Box>
  );
}
