import { HStack, Text } from "@chakra-ui/react";
import React from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import { isSimpleChatMessage } from "shared/conversationUtils";
import { MessageForRendering } from "src/Context/AssistantContext";
import { replaceReferenceCiteWithMatchingReferenceLink } from "./messageUtils";

interface CopyMessageProps {
  message: MessageForRendering;
  chunkToArticleReferenceNumberMap: Record<string, string>;
  articleIdToReferenceNumberMap: Record<string, number>;
  setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
  isCopied: boolean;
}

export const CopyMessage: React.FC<CopyMessageProps> = ({
  message,
  chunkToArticleReferenceNumberMap,
  articleIdToReferenceNumberMap,
  setIsCopied,
  isCopied,
}) => {
  if (!isSimpleChatMessage(message)) return null;

  const handleClick = () => {
    // Copy to clipboard in a google doc format
    navigator.clipboard.writeText(message.content);
    replaceReferenceCiteWithMatchingReferenceLink({
      message,
      chunkToArticleReferenceNumberMap,
      articleIdToReferenceNumberMap,
    });
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <HStack cursor="pointer" onClick={handleClick}>
      <MdOutlineContentCopy fontSize="12px" />
      <Text fontSize="12px">{isCopied ? "Copied" : "Copy"}</Text>
    </HStack>
  );
};
