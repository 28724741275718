import { useHistory, useLocation } from "react-router-dom";

// Predefined query parameters
export const QUERY_PARAMS = {
  modulesDrawer: booleanParam("modulesDrawer"),
} as const;

export interface QueryParam<T> {
  key: string;
  parse: (value: string | null) => T;
  serialize: (value: T) => string;
}

export function useQueryParam<T>(
  param: QueryParam<T>
): [T, (value: T) => void] {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const value = param.parse(searchParams.get(param.key));

  const setValue = (newValue: T) => {
    const newSearchParams = new URLSearchParams(location.search);
    const serialized = param.serialize(newValue);

    if (serialized === "") {
      newSearchParams.delete(param.key);
    } else {
      newSearchParams.set(param.key, serialized);
    }

    history.replace({
      pathname: location.pathname,
      search: newSearchParams.toString()
        ? `?${newSearchParams.toString()}`
        : "",
    });
  };

  return [value, setValue];
}

// Helper for boolean flags
export function booleanParam(key: string): QueryParam<boolean> {
  return {
    key,
    parse: (value) => value === "1",
    serialize: (value) => (value ? "1" : ""),
  };
}

// Helper for enum values
export function enumParam<T extends string>(
  key: string,
  values: readonly T[],
  defaultValue: T
): QueryParam<T> {
  return {
    key,
    parse: (value) =>
      value && values.includes(value as T) ? (value as T) : defaultValue,
    serialize: (value) => value,
  };
}

// Helper for string values
export function stringParam(
  key: string,
  defaultValue: string
): QueryParam<string> {
  return {
    key,
    parse: (value) => value ?? defaultValue,
    serialize: (value) => value,
  };
}
