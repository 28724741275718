import React from "react";
import { useParams } from "react-router-dom";
import { ArticleDoc } from "shared/articleTypes";
import { WidgetMessageData } from "shared/conversation";
import { isSimpleChatMessage } from "shared/conversationUtils";
import { MessageForRendering } from "src/Context/AssistantContext";
import FloatingWindow from "../Elements/SaveToInsightsFloatingWindow";
import { makeArticleIdToReferenceNumberMap } from "../assistant/Message/messageUtils";
import { useSaveSelectionToInsights } from "./useTextSelection";

function extractDigits(inputString: string) {
  // regex to match digits in the format [1]{1}, [2]{2}, etc.
  const regex = /\[(\d)\]\{\1\}/g;

  // Find all matches in the string
  const matches = inputString.match(regex);

  // Extract just the digits from the matches
  const digits = matches ? matches.map((match) => match[1]) : [];

  return digits;
}

export type SourceArticle = {
  uid: ArticleDoc["uid"];
  title: ArticleDoc["title"];
  url: ArticleDoc["url"];
  icon: ArticleDoc["icon"];
};

/**
 * Retrieves source articles based on the provided message and selected text.
 * Inside the selected text, the function looks for reference numbers in the format [1]{1}, [2]{2}, etc.
 *
 * These are linked back to the reference data in the message to get the article IDs and finally
 * the article data from the message referenceData.
 *
 * Returns an array of unique source articles with their uid, title, url, and icon.
 * Explanation
 * -----------
 * This somewhat convoluted process (first creating references from referenceData when we render the message,
 * and then finding the referenceData from the selectedText) is necessary because selectedText only contains
 * the rendered text, but we modify the initial message content to show reference bubbles. But from the bubble
 * text (which is only 1 number, like "1", "2", etc) there is no reliable way to get the article ID. To solve
 * this, invisible reference numbers ([1]{1}, [2]{2}, etc) are added to the message content. In this function we are
 * extracting these numbers from the selected text, and then using them to get the article IDs. The invisible
 * reference numbers are later removed from the selected text before it is saved to insights.
 */
function getSourceArticles(
  /** The message object containing reference data and content */
  msg: Exclude<MessageForRendering, WidgetMessageData>,
  /** The selected text to extract digits from */
  selectedText: string
) {
  const articleIdToReferenceNumberMap = makeArticleIdToReferenceNumberMap(
    msg.referenceData ?? [],
    msg.content
  );

  const digits = extractDigits(selectedText).map((digit) =>
    parseInt(digit, 10)
  );

  const uniqueDigits = [...new Set(digits)];

  // reverse articleIdToReferenceNumberMap
  const articleIdToReferenceNumberMapReverse: Record<string, string> = {};
  Object.entries(articleIdToReferenceNumberMap).forEach(([key, value]) => {
    articleIdToReferenceNumberMapReverse[value] = key;
  });

  // get articleIds from reference numbers
  const articleIds = uniqueDigits.map((digit) => {
    return articleIdToReferenceNumberMapReverse[digit];
  });

  // make an object with articleData
  const sourceArticles = msg.referenceData?.filter((article) =>
    articleIds.includes(article.articleId)
  );

  // sourceArticles can contain multiple copies of the data for the
  // same article, so we need to reduce it to unique articles
  const uniqueSourceArticles = sourceArticles?.reduce((acc, current) => {
    const x = acc.find((item) => item.uid === current.articleId);
    if (!x) {
      return acc.concat([
        {
          uid: current.articleId,
          title: current.articleTitle,
          url: current.articleUrl,
          icon: current.articleIcon,
        },
      ]);
    } else {
      return acc;
    }
  }, [] as SourceArticle[]);

  return uniqueSourceArticles;
}

// Add these type definitions
type WithHandleMouseUp = {
  handleMouseUp?: (event: React.MouseEvent) => void;
};

/*
  This is a higher order component that wraps a component with the functionality
  to save a text selection to insights. It returns a component that takes the
  same props as the original component, but also takes a handleMouseUp prop.
  The handleMouseUp prop should be passed to the onMouseUp event handler of the
  component that is wrapped by this higher order component.
*/
export const withSaveSelectionToInsights = <P extends object>(
  Component: React.ComponentType<P & WithHandleMouseUp>,
  title: string
) => {
  // eslint-disable-next-line react/display-name
  return (
    props: Omit<P, keyof WithHandleMouseUp> & { message: MessageForRendering }
  ) => {
    const { projectId } = useParams() as {
      projectId: string;
    };
    const {
      selectedText,
      selectionPosition,
      handleMouseUp,
      clearSelection,
      handleButtonClick,
    } = useSaveSelectionToInsights(projectId, title);

    let sourceArticles: SourceArticle[] | undefined;
    if (isSimpleChatMessage(props.message)) {
      sourceArticles = getSourceArticles(props.message, selectedText);
    }

    const selectedTextWithoutReferenceNumbers = selectedText.replace(
      / \[\d\]\{\d\}\d/g,
      ""
    );

    return (
      <>
        <Component {...(props as P)} handleMouseUp={handleMouseUp} />
        {selectedText && (
          <FloatingWindow
            text={selectedTextWithoutReferenceNumbers}
            position={selectionPosition}
            onClickAway={clearSelection}
            onButtonClick={handleButtonClick}
            sourceArticles={sourceArticles}
          />
        )}
      </>
    );
  };
};
