import { gapi } from "gapi-script";
import md5 from "md5";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ArticleType, IndexingStatus } from "shared/articleTypes";
import { useAuth } from "src/Auth/AuthProvider";
import { useContentContext } from "src/Context/ContentContext";
import { DISCOVERY_DOCS, SCOPES } from "src/data/constants";
import { createArticle } from "src/db/article";

const useDrive = () => {
  const [userTokenClient, setUserTokenClient] = useState<
    typeof gapi.auth2.GoogleAuth | null
  >();
  const { currentUser } = useAuth();
  const { projectId } = useParams();
  const { showToaster } = useContentContext();

  useEffect(() => {
    gapi.load("client:picker", initializeGooglePicker);
  }, []);

  useEffect(() => {
    const loadGoogleIdentityService = () => {
      //@ts-expect-error because the type of google.accounts.oauth2.initTokenClient is not defined
      const client = google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
        scope: SCOPES,
        callback: "",
      });
      setUserTokenClient(client);
    };
    loadGoogleIdentityService();
  }, []);

  const initializeGooglePicker = async () => {
    await gapi.client.load(DISCOVERY_DOCS);
  };

  const buildAndShowPicker = (accessToken: string) => {
    const picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      //.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)// to enable multiple file selection
      .setDeveloperKey(process.env.REACT_APP_GOOGLE_DRIVE_API_KEY as string)
      .setAppId(process.env.REACT_APP_MESSAGING_SENDER_ID as string)
      .setOAuthToken(accessToken)
      // .addView(google.picker.ViewId.DOCS)
      // show only the .docs files
      .addView(
        new google.picker.DocsView(google.picker.ViewId.DOCUMENTS).setMimeTypes(
          "application/vnd.google-apps.document"
        ) // limits the documents to google docs
      )
      .addView(new google.picker.DocsUploadView())
      .setCallback((data: google.picker.ResponseObject) =>
        pickerCallback(data, accessToken)
      )
      .build();
    picker.setVisible(true);
  };

  const createPicker = () => {
    try {
      if (!userTokenClient)
        throw new Error("User token client not initialized");
      userTokenClient.callback = (response) => {
        if (response.error != undefined) {
          throw new Error(response.error);
        }
        buildAndShowPicker(response.access_token);
      };
      userTokenClient.requestAccessToken({ prompt: "consent" });
    } catch (err) {
      showToaster("Error", err.message, "error");
    }
  };

  const revokeAccessToken = (accessToken: string) => {
    if (accessToken) {
      //@ts-expect-error because the type of google.accounts.oauth2.initTokenClient is not defined
      google.accounts.oauth2.revoke(accessToken);
    }
  };

  const saveSelectedFile = async (
    fileContent: string,
    documentTitle: string,
    fileUrl: string
  ) => {
    const payload = {
      createdAt: Date.now(),
      createdBy: currentUser.uid,
      type: "googleDoc" as ArticleType,
      source: "drive",
      title: documentTitle,
      plainText: fileContent,
      indexingStatus: {
        status: "pending" as IndexingStatus,
      },
      url: fileUrl,
    };

    await createArticle({ projectId, articleId: md5(fileUrl), payload });
    showToaster("Article added", "Your article has been added", "success");
  };

  const pickerCallback = async (data, access_token) => {
    if (data.action === google.picker.Action.PICKED) {
      const document = data[google.picker.Response.DOCUMENTS][0];
      const fileId = document[google.picker.Document.ID];

      if (
        document[google.picker.Document.MIME_TYPE] ===
        "application/vnd.google-apps.document"
      ) {
        const res = await gapi.client.drive.files.export({
          fileId: fileId,
          alt: "media",
          mimeType: "text/plain",
        });
        const fileName = document[google.picker.Document.NAME];
        const fileUrl = document[google.picker.Document.URL];
        const fileContent: string = res.body;
        saveSelectedFile(fileContent, fileName, fileUrl);
      } else {
        showToaster(
          "Invalid file",
          "The selected file is currently not supported. Please select a Google Doc file.",
          "error"
        );
      }
      revokeAccessToken(access_token);
    }
  };

  const loadDriveClient = async () => {
    createPicker();
  };

  return {
    loadDriveClient,
  };
};

export default useDrive;
