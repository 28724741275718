import { Box, Center, Spinner, Text } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { ConversationDoc, MessageData } from "shared/conversation";
import { useAssistantContext } from "../../Context/AssistantContext";
import { useAssistantConversations } from "../../db/assistant";
import { getConversationTitle } from "../Utils/assistant";
import { shortenText } from "../Utils/shortenText";

const getMessageSnippet = (message: MessageData | undefined): string => {
  if (!message || message.type !== "chat") return "";
  if (!message.content && !message.inputValue) return "";

  const textToShorten = message.content || message.inputValue;
  return shortenText(textToShorten, 150);
};

const ConversationCard: React.FC<{
  conversation: ConversationDoc;
  onClick: () => void;
}> = ({ conversation, onClick }) => {
  const snippet = getMessageSnippet(conversation.messages?.[0]);

  return (
    <Box
      border="1px solid"
      borderColor="rgba(0, 0, 0, 0.3)"
      p={4}
      rounded="md"
      mb="3"
      cursor="pointer"
      onClick={onClick}
      key={conversation.uid}
    >
      <Text fontSize="sm">{getConversationTitle(conversation)}</Text>
      <Text fontSize="xs" mt="2" color="rgba(0, 0, 0, 0.54)">
        {snippet}
      </Text>
    </Box>
  );
};

const AssistantHistory: React.FC = () => {
  const { setConversationId, setSubscribeToConversationDoc, setViewMode } =
    useAssistantContext();
  const { projectId } = useParams();
  const { data: assistantConversations, isLoading } =
    useAssistantConversations(projectId);

  if (isLoading || !assistantConversations) {
    return (
      <Box my={16}>
        <Center>
          <Spinner size="xl" />
        </Center>
      </Box>
    );
  }

  const handleConversationSelect = (conversationId: string) => {
    setConversationId(conversationId);
    setSubscribeToConversationDoc(true);
    setViewMode("CHAT");
  };

  return (
    <Box mt={2} maxH="85vh" overflow="auto" p={2}>
      {assistantConversations.map((conversation) => (
        <ConversationCard
          key={conversation.uid}
          conversation={conversation}
          onClick={() => handleConversationSelect(conversation.uid)}
        />
      ))}
    </Box>
  );
};

export default AssistantHistory;
