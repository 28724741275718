import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ArticleDoc } from "shared/articleTypes";
import { getAllArticles } from "../db/article";
import { useProjectContext } from "./ProjectContext";

interface ArticleContextType {
  selectedArticleIds: string[];
  selectArticle: (articleId: string) => void;
  clearSelectedArticles: () => void;
  selectedArticlesData: ArticleDoc[];
  setSelectedArticleIds: (selectedArticleIds: string[]) => void;
  allUnfilteredArticles: ArticleDoc[] | undefined;
  setShowArticleCopyMenu: (showArticleCopyMenu: boolean) => void;
  showArticleCopyMenu: boolean;
  activeArticle?: ArticleDoc;
  setActiveArticle: (activeArticle: ArticleDoc | undefined) => void;
  articleIdTitleMap: Map<ArticleDoc["uid"], ArticleDoc["title"]>;
}

const ArticleContext = React.createContext<ArticleContextType | undefined>(
  undefined
);

export const ArticleContextProvider = ({ children }) => {
  const { articleId } = useParams();
  const { currentProjectId } = useProjectContext();
  const [allUnfilteredArticles, setAllUnfilteredArticles] = useState<
    ArticleDoc[] | undefined
  >(undefined);
  const [activeArticle, setActiveArticle] = useState<ArticleDoc | undefined>(
    undefined
  );
  const [selectedArticleIds, setSelectedArticleIds] = useState<string[]>([]);
  const [selectedArticlesData, setSelectedArticlesData] = useState<
    ArticleDoc[]
  >([]);
  const [showArticleCopyMenu, setShowArticleCopyMenu] = useState(false);
  // provides an easy way of getting article titles by their ids
  const [articleIdTitleMap, setArticleIdTitleMap] = useState<
    Map<ArticleDoc["uid"], ArticleDoc["title"]>
  >(new Map());

  // useEffect(() => {
  //   console.log("selectedArticlesData :>> ", selectedArticlesData);
  // }, [selectedArticlesData]);

  const selectArticle = (articleId) => {
    if (!articleId) {
      throw new Error("articleId is required");
    }
    setSelectedArticleIds((selectedArticleIds) => [
      ...selectedArticleIds,
      articleId,
    ]);
  };

  const clearSelectedArticles = () => {
    setSelectedArticleIds([]);
  };

  /**
   * Fetch all project articles when the current project id changes
   */
  useEffect(() => {
    if (!currentProjectId) {
      setSelectedArticleIds([]);
      setAllUnfilteredArticles(undefined);
      return;
    }

    let unsubscribe = () => {};
    unsubscribe = getAllArticles({
      projectId: currentProjectId,
      callback: (articles) => {
        setAllUnfilteredArticles(articles);
      },
    });

    return () => {
      unsubscribe();
      setAllUnfilteredArticles(undefined);
    };
  }, [currentProjectId]);

  /**
   * Set selected article when the articleId in the URL changes
   */
  useEffect(() => {
    if (!articleId) {
      clearSelectedArticles();
      return;
    } else {
      clearSelectedArticles();
      selectArticle(articleId);
    }
  }, [articleId]);

  /**
   * Sync active article with the articleId in the URL
   */
  useEffect(() => {
    if (!articleId) setActiveArticle(undefined);
    if (!allUnfilteredArticles) setActiveArticle(undefined);

    const activeArticle = allUnfilteredArticles?.find(
      (article) => article.uid === articleId
    );

    if (activeArticle) {
      setActiveArticle(activeArticle);
    }
  }, [articleId, allUnfilteredArticles]);

  /**
   * Sync selected articles' data with the selected article ids
   */
  useEffect(() => {
    if (selectedArticleIds.length === 0) {
      setSelectedArticlesData([]);
      return;
    }

    if (!allUnfilteredArticles) {
      return;
    }

    if (allUnfilteredArticles.length === 0) {
      return;
    }

    const filteredArticlesBy = allUnfilteredArticles.filter((item) => {
      return selectedArticleIds.includes(item.uid);
    });
    setSelectedArticlesData(filteredArticlesBy);
  }, [selectedArticleIds]);

  /**
   * Sync articleIdTitleMap with allUnfilteredArticles
   */
  useEffect(() => {
    if (!allUnfilteredArticles) {
      return;
    }

    if (allUnfilteredArticles.length === 0) {
      return;
    }

    const articleIdTitleMap = new Map<ArticleDoc["uid"], ArticleDoc["title"]>();
    allUnfilteredArticles.forEach((article) => {
      articleIdTitleMap.set(article.uid, article.title);
    });

    setArticleIdTitleMap(articleIdTitleMap);
  }, [allUnfilteredArticles]);

  return (
    <ArticleContext.Provider
      value={{
        selectedArticleIds,
        selectArticle,
        clearSelectedArticles,
        selectedArticlesData,
        setSelectedArticleIds,
        allUnfilteredArticles,
        setShowArticleCopyMenu,
        showArticleCopyMenu,
        activeArticle,
        setActiveArticle,
        articleIdTitleMap,
      }}
    >
      {children}
    </ArticleContext.Provider>
  );
};

export const useArticle = () => {
  const context = useContext(ArticleContext);
  if (context === undefined) {
    throw new Error("context must be used within a ArticleProvider");
  }
  return context;
};
export default ArticleContext;
