import { Box, Flex, HStack, Heading, Text, VStack } from "@chakra-ui/react";
import { updateDoc } from "firebase/firestore";
import React from "react";
import { MdClose, MdInfoOutline } from "react-icons/md";
import { getCustomizationData } from "src/Components/Utils/customizationUtils";
import { useAuth } from "../../../Auth/AuthProvider";
import { colors } from "../../../theme/foundations/colors";

const customization = getCustomizationData();

export const WelcomeToTemplatesSection = () => {
  const { userDoc, userDocRef } = useAuth();

  const handleClose = () => {
    updateDoc(userDocRef, {
      hasSeenWelcomeToTemplates: true,
    });
  };

  if (userDoc.hasSeenWelcomeToTemplates) {
    return null;
  }
  return (
    <Box p="0" bg="white" borderRadius="md" boxShadow="sm">
      <Flex gap="12px" p="12px" pb="24px">
        <MdInfoOutline color={colors.accentHighlights} fontSize="16px" />
        <VStack w="100%" gap="8px">
          <HStack alignContent="start" w="100%" justify="space-between">
            <Heading fontSize="10px" color="black" fontWeight={600}>
              {customization.template.heading}
            </Heading>
            <MdClose
              color="#757575"
              fontSize="16px"
              cursor="pointer"
              onClick={handleClose}
            />
          </HStack>
          <Text
            color="black"
            opacity={0.54}
            fontSize="10px"
            lineHeight={1.1}
            w="95%"
            alignSelf={"flex-start"}
          >
            {customization.template.body}
          </Text>
        </VStack>
      </Flex>
    </Box>
  );
};
