import { HStack, IconButton, Text } from "@chakra-ui/react";
import React from "react";
import StopGenerationIconComponent from "../Icons/StopGenerationIcon";

export function StopGeneration({ handleStopGeneration }) {
  return (
    <HStack>
      <Text fontSize="xs" color="#888A94">
        Stop generating
      </Text>
      <IconButton
        // py="7px"
        color="#fff"
        bg={"primary"}
        aria-label="Stop generation"
        icon={<StopGenerationIconComponent style={{ fontSize: "25px" }} />}
        onClick={handleStopGeneration}
        cursor={"pointer"}
        // mr={3}
        borderRadius={"4px"}
      />
    </HStack>
  );
}
