// Unique identifier for each widget type
export const WidgetType = {
  PLAIN_TEXT: "plain_text",
  SEARCH_RESULTS: "search_results",
} as const;

export type WidgetType = (typeof WidgetType)[keyof typeof WidgetType];

// Base interface for all widget configs
interface BaseWidgetData {
  widgetType: WidgetType;
}

// Plain Text Widget Config
export interface PlainTextWidgetData extends BaseWidgetData {
  widgetType: typeof WidgetType.PLAIN_TEXT;
  text: string;
}

// Search Results Widget Config
export interface SearchResultItem {
  title: string;
  url: string;
  snippet: string;
}

export interface SearchResultsWidgetData extends BaseWidgetData {
  widgetType: typeof WidgetType.SEARCH_RESULTS;
  results: SearchResultItem[];
}

// Union type for all widget configs
export type WidgetData = PlainTextWidgetData | SearchResultsWidgetData;
