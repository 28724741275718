import { useEffect, useState } from "react";
import { getConversationCollection } from "src/db/conversation";
import { createSnippet } from "../../db/snippet";
import { auth, doc, getDoc } from "../../firebase";
import { SourceArticle } from "./withSaveSelectionToInsights";

/**
 * Custom hook to handle text selection. It returns the selected text and the position of the selection.
 * One particular use case is to display a floating window with a button to save the selected text to insights
 * (See SaveToInsightsFloatingWindow.js).
 */
export const useTextSelection = () => {
  const [selectedText, setSelectedText] = useState("");
  const [selectionPosition, setSelectionPosition] = useState({ x: 0, y: 0 });
  const [mousedownInsideSelection, setMousedownInsideSelection] =
    useState(false);

  const handleMouseUp = (event) => {
    const selection = window.getSelection();
    // Clear the selection if mousedown occurred inside the selection
    if (mousedownInsideSelection) {
      setSelectedText("");
      setMousedownInsideSelection(false);
    } else {
      if (!selection?.isCollapsed) {
        // check if text is selected
        setSelectedText(selection?.toString() ?? "");
        setSelectionPosition({ x: event.clientX, y: event.clientY });
      } else {
        setSelectedText("");
      }
    }
  };

  // This effect is used to detect if the mousedown event occurred inside the selection.
  // Without this, the selected text is not cleared when the user clicks inside already selected text.
  // This causes an issue down the line with Save insight button appearing when no text is selected.
  useEffect(() => {
    const handleMouseDown = (event) => {
      const selection = window.getSelection();
      if (
        !selection?.isCollapsed &&
        selection?.containsNode(event.target, true)
      ) {
        setMousedownInsideSelection(true);
      } else {
        setMousedownInsideSelection(false);
      }
    };

    // clearing selection on scroll is a workaround for Save Insight
    // button not sticking to the page when scrolling, and instead
    // floating with the scroll. If it is possible to fix this issue,
    // this workaround can be removed.
    const handleScroll = () => {
      // clearSelection();
    };

    document.addEventListener("scroll", handleScroll, true);
    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("scroll", handleScroll, true);
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const clearSelection = () => {
    setSelectedText("");
    // remove selection when user clicks away
    // try catch just in case this fails, it is not critical
    try {
      if (window.getSelection) {
        if (window.getSelection()?.empty) {
          // Chrome
          window.getSelection()?.empty();
        } else if (window.getSelection()?.removeAllRanges) {
          // Firefox
          window.getSelection()?.removeAllRanges();
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return {
    selectedText,
    selectionPosition,
    handleMouseUp,
    clearSelection,
  };
};

/**
 * Custom hook to save a text selection to insights.
 *
 * @param {*} projectId
 * @returns
 */
export const useSaveSelectionToInsights = (projectId, insightGroupTitle) => {
  const { selectedText, selectionPosition, handleMouseUp, clearSelection } =
    useTextSelection();

  const handleButtonClick = async ({
    text,
    conversationId,
    sourceArticles,
  }: {
    text: string;
    conversationId: string;
    sourceArticles?: SourceArticle[];
  }) => {
    const createInsightAndClearSelection = async ({ title }) => {
      if (!auth.currentUser?.uid) {
        throw new Error("User not logged in");
      }
      await createSnippet({
        projectId,
        snippetType: "assistantInsight",
        text,
        authorId: auth.currentUser.uid,
        curated: true,
        title,
        sourceArticles:
          sourceArticles?.length && sourceArticles?.length > 0
            ? sourceArticles
            : undefined,
      });
      clearSelection();
    };

    if (!conversationId) {
      await createInsightAndClearSelection({ title: insightGroupTitle });
      return;
    }
    // try to retrieve the conversationId from the url to get the title
    try {
      const conversationTitle = await getConversationTitle(
        projectId,
        conversationId
      );
      await createInsightAndClearSelection({ title: conversationTitle });
    } catch (error) {
      console.log("error :>> ", error);
      await createInsightAndClearSelection({ title: insightGroupTitle });
    }
  };

  return {
    selectedText,
    selectionPosition,
    handleMouseUp,
    clearSelection,
    handleButtonClick,
  };
};

export const getConversationTitle = async (projectId, conversationId) => {
  const conversationDocRef = doc(
    getConversationCollection(projectId),
    conversationId
  );
  const docSnap = await getDoc(conversationDocRef);
  return docSnap.data()?.title;
};
