import { useQuery } from "@tanstack/react-query";
import { ConversationDoc } from "shared/conversation";
import {
  arrayUnion,
  auth,
  db,
  doc,
  getDocs,
  or,
  q,
  setDoc,
  where,
} from "../firebase";
import {
  assistantChatRequestCollection,
  getConversationCollection,
} from "./conversation";

export const updateConversation = async (
  projectId,
  conversationId,
  message
) => {
  const response = await setDoc(
    doc(db, "projects", projectId, "conversations", conversationId),
    {
      messages: arrayUnion(message),
    },
    { merge: true }
  );
  return response;
};

export const saveAssistantChatRequest = async (
  projectId,
  conversationId,
  messageId
) => {
  if (!auth.currentUser) {
    throw new Error("User not authenticated");
  }
  const response = await setDoc(
    doc(assistantChatRequestCollection, messageId),
    {
      projectId,
      conversationId,
      userId: auth.currentUser.uid,
    }
  );
  return response;
};

export const getAssistantConversationsQuery = async (projectId) => {
  console.log("getAssistantConversationsQuery projectId :>> ", projectId);
  const conversationsQuery = q(
    getConversationCollection(projectId),
    or(
      where("userId", "==", auth.currentUser?.uid),
      where("shared", "==", true)
    )
  );

  const querySnapshot = await getDocs(conversationsQuery);
  const conversationsData: ConversationDoc[] = [];
  querySnapshot.forEach((doc) => {
    conversationsData.push(doc.data());
  });

  // sort by timestamp of the last message
  conversationsData.sort((a, b) => {
    const aLastMessage = a.messages[a.messages.length - 1];
    const bLastMessage = b.messages[b.messages.length - 1];
    return bLastMessage.createdAt - aLastMessage.createdAt;
  });

  return conversationsData;
};

export const useAssistantConversations = (projectId) => {
  return useQuery({
    queryKey: ["assistantConversations", projectId],
    queryFn: () => getAssistantConversationsQuery(projectId),
    enabled: !!projectId && !!auth.currentUser,
  });
};
