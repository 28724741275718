import {
  Editable,
  EditableInput,
  EditableInputProps,
  EditablePreview,
  EditablePreviewProps,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const EditableText = ({
  text,
  isEditable = false,
  submitHandler,
  inputProps,
  placeholder = "Click to edit",
}: {
  text: string;
  isEditable?: boolean;
  submitHandler: (value: string) => void;
  inputProps?: EditableInputProps & EditablePreviewProps;
  placeholder?: string;
}) => {
  const [value, setValue] = useState(text);
  const [initialValue, setInitialValue] = useState(text);

  useEffect(() => {
    setValue(text);
    setInitialValue(text);
  }, [text]);

  const handleSubmission = (value) => {
    if (!value) {
      setValue(initialValue);
      return;
    }
    submitHandler(value);
  };

  const updateValue = (value) => {
    if (!value) {
      setValue(initialValue);
    }
    setValue(value);
  };

  console.log("value", value);
  console.log("initialValue", initialValue);

  return (
    <Editable
      onSubmit={handleSubmission}
      isDisabled={isEditable}
      value={value}
      onChange={(value) => updateValue(value)}
      submitOnBlur
      border="1px solid"
      borderColor="transparent"
      _hover={{ borderColor: "#4299e1", borderRadius: "md" }}
      placeholder={placeholder}
    >
      <EditablePreview pr={2} pl={1} noOfLines={1} {...inputProps} />
      <EditableInput pl={1} noOfLines={1} {...inputProps} />
    </Editable>
  );
};

export default EditableText;
