import { Box, Center, CircularProgress, Flex, Text } from "@chakra-ui/react";
import md5 from "md5";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";
import { useContentContext } from "../../Context/ContentContext";
import { MAX_FILE_SIZE } from "../../data/constants";
import { createArticle, getArticleById } from "../../db/article";
import useNotification from "../../hooks/useNotification";
import { saveFile } from "../../services/firebaseStorageSrv";
import { removeUndefinedNull } from "../Utils/dbUtils";

const MIME_TYPE_TO_ARTICLE_TYPE = {
  "application/pdf": "pdf",
  "text/csv": "table",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "table",
  "application/vnd.ms-excel": "table",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "pptx",
};

const MIME_TYPE_HUMAN_READABLE = {
  "application/pdf": "PDF",
  "text/csv": "CSV",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "XLSX",
  "application/vnd.ms-excel": "XLS",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "DOCX",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "PPTX",
};

const FileUploadComponent = ({ onClose }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleClick = () => inputRef.current?.click();
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [uploadTracker, setUploadTracker] = useState(0); // tracks the number of files uploaded
  const [totalFileUpload, setTotalFileUpload] = useState(0); // tracks the number of files uploaded
  const [articleId, setArticleId] = useState(null);
  // const history = useHistory();
  const [currentAction, setCurrentAction] = useState("Uploading");
  const { content } = useContentContext();
  const { notify } = useNotification();

  const { projectId } = useParams();
  const { currentUser } = useAuth();
  /**
   * This is a defensive measure to let user know that if the file upload dialog does not appear,
   * they should restart their browser. This is a known issue with Chrome.
   */
  const [fileUploadDialogClickCounter, setFileUploadDialogClickCounter] =
    useState(0);

  const resetValues = () => {
    setFileIsUploading(false);
    setUploadTracker(0);
    setTotalFileUpload(0);
    setArticleId(null);
    setFileUploadDialogClickCounter(0);
    onClose();
  };

  const addArticleToProject = async (articleData) => {
    const createdAt = Date.now();
    const createdBy = currentUser.uid;
    const articleId = md5(articleData.url);
    const payload = {
      createdAt,
      createdBy,
      ...articleData,
      uid: articleId || md5(articleData.url),
    };
    removeUndefinedNull(payload);
    setArticleId(articleId);
    await createArticle({ projectId, articleId, payload });
  };

  const handleFileUpload = async (e) => {
    try {
      const files = e.target.files;
      const acceptedMimeTypes =
        content.acceptedMimeTypes || Object.keys(MIME_TYPE_TO_ARTICLE_TYPE);

      setTotalFileUpload(files.length);
      setFileIsUploading(true);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // verify MIME type, because the input field preferences can be overridden
        if (!acceptedMimeTypes.includes(file.type)) {
          notify({
            title: "Error",
            description: `${
              file.name
            } file type is not supported. Please upload a file with the following extensions: ${acceptedMimeTypes
              .map((mime) => MIME_TYPE_HUMAN_READABLE[mime])
              .join(", ")}`,
            status: "error",
            duration: 10000,
          });
          continue; // Skip processing this file or you could also break to stop further uploads
        }
        if (file.size > MAX_FILE_SIZE) {
          throw new Error("File size is too large");
        }
        const downloadUrl = await saveFile({
          file,
          // pathName: `pdfUploaded/${currentUser.uid}`,
          path: `project_data/${projectId}/uploaded_pdf`,
          isProfilePicture: false,
        });
        await addArticleToProject({
          url: downloadUrl,
          type:
            content.forceArticleType || MIME_TYPE_TO_ARTICLE_TYPE[file.type],
          source: "local",
          // isHidden: true,
          // title is the name of the file without the extension
          title: file.name.replace(/\.[^/.]+$/, ""),
          indexingStatus: {
            status: "pending",
          },
        });
        setUploadTracker((prev) => prev + 1);
      }
      // setFileIsUploading(false);
      resetValues();
      setCurrentAction("Processing");
    } catch (err) {
      console.log(err);
      resetValues();
      notify({
        title: "Error",
        description: err.message,
        status: "error",
        duration: 5000,
      });
    }
  };

  useEffect(() => {
    let unsubscribe = () => {};
    if (articleId) {
      //listen to the article document update
      unsubscribe = getArticleById({
        projectId,
        articleId,
        callback: (article) => {
          if (article.isExtractionCompleted) {
            notify({
              title: "Success",
              description: "File successfully added to project",
              status: "success",
              duration: 5000,
            });
            resetValues();
          }
        },
      });
    }
    return () => {
      console.log("Unsubscribing from article update");
      unsubscribe();
    };
  }, [articleId]);

  return (
    <Box>
      <Box
        mt="8"
        rounded="md"
        border="1px dashed"
        borderColor="rgba(255, 255, 255, 0.5)"
      >
        {!fileIsUploading ? (
          <>
            <Flex
              onClick={() => {
                setFileUploadDialogClickCounter((prev) => prev + 1);
              }}
            >
              <input
                type="file"
                multiple={true}
                hidden
                accept={(
                  content.acceptedMimeTypes ||
                  Object.keys(MIME_TYPE_TO_ARTICLE_TYPE)
                ).join(",")}
                ref={inputRef}
                onChange={handleFileUpload}
              />
            </Flex>
            <Box
              onClick={handleClick}
              py={16}
              fontSize="xs"
              textAlign="center"
              cursor="pointer"
            >
              <Text fontWeight="black">Click to choose files to upload</Text>
              <Text>
                (
                {content.acceptedMimeTypes
                  ? content.acceptedMimeTypes
                      .map((mime) => {
                        return MIME_TYPE_HUMAN_READABLE[mime];
                      })
                      .join(", ")
                  : Object.values(MIME_TYPE_HUMAN_READABLE).join(", ")}
                )
              </Text>
              <Center>
                <Text
                  visibility={
                    fileUploadDialogClickCounter > 2 ? "visible" : "hidden"
                  }
                  fontSize="xs"
                  color="whiteAlpha.700"
                  pt={10}
                  position={"absolute"}
                  // display="block"
                >
                  If no file upload dialog appeared, please restart your browser
                  and try again.
                </Text>
              </Center>
            </Box>
          </>
        ) : (
          <Box py={16}>
            <Center fontSize="xs" textAlign="center">
              <CircularProgress size="28px" isIndeterminate color="white" />
            </Center>
            <Text mt="2" fontSize="xs" textAlign="center" fontWeight="black">
              {currentAction} {uploadTracker} / {totalFileUpload} ...
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FileUploadComponent;
