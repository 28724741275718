import { useEffect } from "react";

interface AutoFocusOptions {
  /**
   * Delay in milliseconds before focusing the element
   * Useful when dealing with animations or waiting for DOM elements to be ready
   */
  delay?: number;
  /**
   * Whether to focus only on initial render or every time the dependencies change
   */
  onlyOnce?: boolean;
  /**
   * Additional condition that must be true for the focus to occur
   * For example, a modal being open
   */
  shouldFocus?: boolean;
}

/**
 * Hook to automatically focus an element when it mounts or when dependencies change
 * @param elementId The ID of the element to focus
 * @param deps Array of dependencies that should trigger a re-focus when changed
 * @param options Configuration options for the auto-focus behavior
 */
export const useAutoFocus = (
  elementId: string,
  deps: React.DependencyList = [],
  options: AutoFocusOptions = {}
) => {
  const { delay = 0, onlyOnce = false, shouldFocus = true } = options;

  useEffect(
    () => {
      if (!shouldFocus) return;

      const timeoutId = setTimeout(() => {
        const element = document.getElementById(elementId);
        element?.focus();
      }, delay);

      return () => clearTimeout(timeoutId);
    },
    onlyOnce ? [] : deps
  ); // If onlyOnce is true, run only on mount
};
