import { useCallback, useEffect } from "react";

type KeyboardShortcut = {
  key: string;
  ctrlKey?: boolean;
  altKey?: boolean;
  shiftKey?: boolean;
  metaKey?: boolean;
  action: () => void;
  description?: string;
};

type KeyboardShortcutOptions = {
  preventDefault?: boolean;
  stopPropagation?: boolean;
  // Only trigger when focused on specific element(s)
  targetElementIds?: string[];
};

export const useKeyboardShortcut = (
  shortcuts: KeyboardShortcut[],
  options: Partial<KeyboardShortcutOptions> = {}
) => {
  const {
    preventDefault = true,
    stopPropagation = true,
    targetElementIds,
  } = options;

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      // If we have target elements specified, check if the event target is one of them
      if (targetElementIds?.length) {
        const targetElement = event.target as HTMLElement;
        if (!targetElementIds.includes(targetElement.id)) {
          return;
        }
      }

      const matchingShortcut = shortcuts.find(
        (shortcut) =>
          shortcut.key.toLowerCase() === event.key.toLowerCase() &&
          !!shortcut.ctrlKey === event.ctrlKey &&
          !!shortcut.altKey === event.altKey &&
          !!shortcut.shiftKey === event.shiftKey &&
          !!shortcut.metaKey === event.metaKey
      );

      if (matchingShortcut) {
        if (preventDefault) {
          event.preventDefault();
        }
        if (stopPropagation) {
          event.stopPropagation();
        }
        matchingShortcut.action();
      }
    },
    [shortcuts, preventDefault, stopPropagation, targetElementIds]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  // Return the list of active shortcuts for documentation purposes
  return shortcuts;
};
