import React from "react";
import { PlainTextWidgetData } from "../../../functions/shared/widgets";
import { MessageWrapper } from "../assistant/Message/MessageWrapper";
import { WidgetComponentProps } from "./WidgetRegistry";

export default function PlainTextWidget({
  data: config,
}: WidgetComponentProps<PlainTextWidgetData>) {
  console.log("PlainTextWidget config :>> ", config);
  return <MessageWrapper chatRole="assistant">{config.text}</MessageWrapper>;
}
