import React from "react";
import {
  CLIENT_CUSTOMIZATION_DATA,
  ClientCustomization,
  DOMAIN_PROJECT_MAP,
} from "../../data/ClientCustomizationData";

class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = "ValidationError";
    this.stack = ""; // Remove the stack trace
  }
}

/**
 * Recursively validates an object against a default object, ensuring that all properties from the default object are present in the client object.
 * If a property is missing in the client object, it will be added with the default value.
 * If a property is an object, the function will recursively validate the nested object.
 * If a property is missing in the default object, an error will be thrown.
 *
 * @param {object} defaultObj - The default object to validate against.
 * @param {object} clientObj - The client object to validate.
 * @param {string} [path=""] - The current path of the object being validated (used for error messages).
 * @throws {Error} If a property is missing in the default object.
 * @returns {object} The validated object with default values applied
 */
const validateObject = (defaultObj, clientObj, path = "") => {
  // Create a deep copy of the client object to avoid modifying read-only properties
  const validatedObj = { ...clientObj };

  for (const key in defaultObj) {
    if (
      typeof defaultObj[key] === "object" &&
      !Array.isArray(defaultObj[key]) &&
      // Special case: React elements are objects but should be treated as values
      !React.isValidElement(defaultObj[key])
    ) {
      if (!validatedObj[key]) {
        validatedObj[key] = {};
      }
      validatedObj[key] = validateObject(
        defaultObj[key],
        validatedObj[key],
        `${path}.${key}`
      );
    } else {
      if (validatedObj[key] === undefined) {
        validatedObj[key] = defaultObj[key];
      }
    }
  }

  for (const key in validatedObj) {
    if (!(key in defaultObj)) {
      throw new ValidationError(
        `Field '${key}' at '${path}' doesn't have a default value. Add it to the "default" object in CLIENT_CUSTOMIZATION_DATA.`
      );
    }
  }

  return validatedObj;
};

/**
 * Merges the client data with the default data, recursively merging nested objects.
 *
 * @param {Object} clientData - The client data to merge.
 * @param {Object} defaultData - The default data to merge with.
 * @returns {Object} - The merged data.
 */
const mergeWithDefault = (clientData, defaultData) => {
  // If clientData is undefined/null, return a copy of defaultData
  if (!clientData) {
    return { ...defaultData };
  }

  const result = { ...defaultData };
  validateObject(defaultData, clientData);

  // Only process keys if clientData is an object
  if (typeof clientData === "object" && !Array.isArray(clientData)) {
    Object.keys(clientData).forEach((key) => {
      const clientValue = clientData[key];
      if (
        clientValue !== null &&
        typeof clientValue === "object" &&
        !Array.isArray(clientValue) &&
        !React.isValidElement(clientValue)
      ) {
        result[key] = mergeWithDefault(clientValue, defaultData[key]);
      } else {
        result[key] = clientValue;
      }
    });
  }

  return result;
};

export const getCustomizationData = () => {
  const project = process.env.REACT_APP_PROJECT;
  let clientData;

  if (project) {
    clientData = CLIENT_CUSTOMIZATION_DATA[project];
  } else {
    const domain = window.location.hostname;
    const client = DOMAIN_PROJECT_MAP[domain];
    clientData = CLIENT_CUSTOMIZATION_DATA[client];
  }

  if (!clientData) {
    clientData = CLIENT_CUSTOMIZATION_DATA.default;
  }

  const mergedData = mergeWithDefault(
    clientData,
    CLIENT_CUSTOMIZATION_DATA.default
  );

  mergedData.assistant.openingMessage = mergedData.assistant.getOpeningMessage(
    mergedData.assistant.name
  );

  return mergedData as ClientCustomization;
};

const validateAllClients = (defaultData, clientDataMap) => {
  const validatedClientDataMap = { ...clientDataMap };
  Object.keys(clientDataMap).forEach((clientKey) => {
    const clientData = clientDataMap[clientKey];
    validatedClientDataMap[clientKey] = validateObject(
      defaultData,
      clientData,
      `CLIENT_CUSTOMIZATION_DATA.${clientKey}`
    );
  });
  return validatedClientDataMap;
};

// Validate all clients during initialization and update the CLIENT_CUSTOMIZATION_DATA
Object.assign(
  CLIENT_CUSTOMIZATION_DATA,
  validateAllClients(
    CLIENT_CUSTOMIZATION_DATA.default,
    CLIENT_CUSTOMIZATION_DATA
  )
);
