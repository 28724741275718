import { HStack, Icon } from "@chakra-ui/react";
import React from "react";
import { WidgetMessageData } from "shared/conversation";
import ThumbDownIcon from "src/Components/Icons/ThumbDownIcon";
import ThumbUpIcon from "src/Components/Icons/ThumbUpIcon";
import { MessageForRendering } from "src/Context/AssistantContext";

export const MessageFeedback = ({
  message,
  setMsgFeedback,
}: {
  message: Exclude<MessageForRendering, WidgetMessageData>;
  setMsgFeedback: (feedback: 0 | 1 | -1) => void;
}) => {
  return (
    <HStack spacing={2} gap={3}>
      <Icon
        as={ThumbUpIcon}
        fill={message.feedback === 1 ? "black" : "none"}
        boxSize={3}
        cursor="pointer"
        onClick={() => {
          message.feedback === 1 ? setMsgFeedback(0) : setMsgFeedback(1);
        }}
      />
      <Icon
        as={ThumbDownIcon}
        fill={message.feedback === -1 ? "black" : "none"}
        boxSize={3}
        cursor="pointer"
        onClick={() => {
          message.feedback === -1 ? setMsgFeedback(0) : setMsgFeedback(-1);
        }}
      />
    </HStack>
  );
};
