import {
  AbsoluteCenter,
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { RiSparklingLine } from "react-icons/ri";
import { ProjectWithPermission } from "src/Context/ProjectContext";
import { CARD_GAP, CARD_WIDTH } from "src/Pages/UserHomePage/UserHomePage";
import { getCustomizationData } from "../Utils/customizationUtils";
import { ProjectListCard } from "./ProjectListCard";

const STARTING_ROWS_TO_SHOW = 2;
const customization = getCustomizationData();

interface ProjectsListProps {
  /** The array of projects data. */
  projects: ProjectWithPermission[];
  /** The array of currently pinned project IDs. */
  currentlyPinnedProjects: string[];
  /** The title of the row. */
  rowTitle: string;
  /** The icon for the row. */
  icon?: React.ReactElement;
  /** Whether to show the title. */
  showTitle?: boolean;
  /** Whether to show the no projects text. */
  showNoProjectsText?: boolean;
  /** Whether to disable the show more functionality. */
  disableShowMore?: boolean;
  /** The text for the show all projects button. */
  showAllProjectsButtonText?: string;
}

/**
 * Renders a list of pinned and unpinned projects.
 */
export const ProjectsList = ({
  projects,
  currentlyPinnedProjects,
  rowTitle,
  icon,
  showTitle = true,
  showNoProjectsText = false,
  disableShowMore = false,
  showAllProjectsButtonText,
}: ProjectsListProps) => {
  const [visibleRows, setVisibleRows] = useState(STARTING_ROWS_TO_SHOW);
  const [maxVisibleRows, setMaxVisibleRows] = useState(0);
  const [shouldShowMore, setShouldShowMore] = useState(false);

  const totalProjects = useMemo(() => projects.length, [projects]);

  useEffect(() => {
    const handleUpdates = () => {
      const screenWidth = window.innerWidth;
      const numberOfCards = Math.round(
        screenWidth / 2 / (CARD_WIDTH + CARD_GAP)
      );
      const maxRows = Math.ceil(projects.length / numberOfCards);

      setMaxVisibleRows(maxRows);
      setShouldShowMore(maxRows > STARTING_ROWS_TO_SHOW && !disableShowMore);
    };

    handleUpdates();
    window.addEventListener("resize", handleUpdates);
    return () => window.removeEventListener("resize", handleUpdates);
  }, [projects.length, CARD_WIDTH, CARD_GAP, disableShowMore]);

  const ShowMoreButton = () => {
    if (!shouldShowMore) return null;
    const bttnText =
      visibleRows >= maxVisibleRows
        ? `Show less ${showAllProjectsButtonText}`
        : `Show all ${showAllProjectsButtonText}`;
    const bttnIcon =
      visibleRows >= maxVisibleRows ? FiMinusCircle : FiPlusCircle;
    const isExpanded = visibleRows >= maxVisibleRows;
    return (
      <Flex gap={4} my={4} pr={2}>
        <HStack
          cursor="pointer"
          gap="8px"
          onClick={() => {
            if (isExpanded) {
              setVisibleRows(STARTING_ROWS_TO_SHOW);
            } else {
              setVisibleRows(maxVisibleRows);
            }
          }}
        >
          <Icon as={bttnIcon} boxSize="16px" />{" "}
          <Text color="primary" fontWeight="500" opacity="0.87" fontSize="12px">
            {bttnText}
          </Text>
        </HStack>
      </Flex>
    );
  };

  return (
    <Flex flexDir="column">
      {showTitle ? (
        <Box position="relative" my={4}>
          <Divider />
          <AbsoluteCenter px={4} bg="#f8f8f8" fontSize="13px" color="gray.400">
            {icon ? <Box as="span">{icon}</Box> : null}
            {rowTitle} {`[${totalProjects}]`}
          </AbsoluteCenter>
        </Box>
      ) : null}
      {showNoProjectsText ? (
        <Flex w="100%" align="center" py="24" flexDir="column" gap="4px">
          <RiSparklingLine fontSize="40px" />
          {customization.homePage.noProjectText}
        </Flex>
      ) : null}
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="flex-start"
        justifyContent="flex-start"
        gap={`${CARD_GAP}px`}
        gridTemplateColumns={`repeat(auto-fill, minmax(${CARD_WIDTH}px, 1fr))`}
        maxHeight={
          visibleRows ? `${visibleRows * (CARD_WIDTH + CARD_GAP)}px` : "auto"
        }
        overflow="hidden"
        mt="4"
      >
        {projects?.map((projectWithPermission, index) => (
          <ProjectListCard
            isFirstItem={index === 0}
            key={index}
            projectWithPermission={projectWithPermission}
            isPinned={currentlyPinnedProjects?.includes(
              projectWithPermission.data.uid
            )}
          />
        ))}
      </Box>
      <ShowMoreButton />
    </Flex>
  );
};
