import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BsCheckCircle, BsGlobe } from "react-icons/bs";
import { FiPlusCircle } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useAuth } from "src/Auth/AuthProvider";
import { useArticle } from "src/Context/ArticleContext";
import { createArticle } from "src/db/article";
import { SearchResultsWidgetData } from "../../../functions/shared/widgets";
import { WidgetComponentProps } from "./WidgetRegistry";

export interface ArticleItemProps {
  title: string;
  description: string;
  url: string;
}

const ArticleItemTitle: React.FC<{ title: string; url: string }> = ({
  title,
  url,
}) => {
  // Extract hostname for favicon
  const hostname = new URL(url).hostname;
  const faviconUrl = `https://www.google.com/s2/favicons?domain=${hostname}`;

  return (
    <Flex gap="1.5" alignSelf="flex-start" align="center">
      <Image
        loading="lazy"
        src={faviconUrl}
        alt=""
        objectFit="contain"
        w="14px"
        h="14px"
        aspectRatio="1"
      />
      <Text fontSize="10px" fontWeight="bold" color="blackAlpha.900">
        {title}
      </Text>
      <Image
        loading="lazy"
        className="hover-image"
        display="none"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/165d351bdcb803c342d5a57a57e5bf370ce3a602cf2f0870f91444016aa3780f?placeholderIfAbsent=true&apiKey=ffbb3f264c66444e838eafc91f533e20"
        alt=""
        objectFit="contain"
        w="12px"
        aspectRatio="1"
      />
    </Flex>
  );
};
function AddArticleButton(props: {
  projectId: string;
  uid: string;
  title: string;
  url: string;
}) {
  const { allUnfilteredArticles } = useArticle();
  const isArticleInProject = allUnfilteredArticles?.some(
    (article) => article.url === props.url
  );
  return (
    <Tooltip
      label={isArticleInProject ? "Added" : "Add to project"}
      borderRadius="2px"
      background="white"
      color="rgba(0, 0, 0, 0.54)"
      textAlign="center"
      fontFamily="Inter"
      fontSize="10px"
      fontStyle="normal"
      fontWeight="500"
      lineHeight="normal"
      letterSpacing="-0.08px"
      shadow="none"
      placement="top-start"
      hasArrow={false}
      offset={[0, 0]}
    >
      <Button
        className="hover-button"
        display="none"
        position="absolute"
        right="4"
        top="50%"
        transform="translateY(-50%)"
        size="xs"
        variant="ghost"
        onClick={async (e) => {
          if (isArticleInProject) {
            e.preventDefault();
            e.stopPropagation();
            return;
          }
          e.preventDefault();
          e.stopPropagation();
          await createArticle({
            projectId: props.projectId,
            payload: {
              title: props.title,
              url: props.url,
              type: "html",
              createdBy: props.uid,
              indexingStatus: {
                status: "pending",
                message: "",
              },
            },
          });
        }}
      >
        <Icon
          as={isArticleInProject ? BsCheckCircle : FiPlusCircle}
          color={isArticleInProject ? "hsla(120, 25%, 40%, 1)" : undefined}
        />
      </Button>
    </Tooltip>
  );
}

const ArticleItemContent: React.FC<{
  title: string;
  description: string;
  url: string;
}> = ({ title, description, url }) => {
  const { projectId } = useParams() as { projectId: string };
  const { currentUser } = useAuth();
  return (
    <VStack>
      <ArticleItemTitle title={title} url={url} />
      <Flex gap={"4px"} wrap="wrap">
        <Text
          fontSize="12px"
          lineHeight="4"
          color="blackAlpha.500"
          w="90%"
          flexGrow={0}
        >
          {description}
        </Text>
        <AddArticleButton
          projectId={projectId}
          uid={currentUser.uid}
          title={title}
          url={url}
        />
      </Flex>
    </VStack>
  );
};

export const ArticleItem: React.FC<ArticleItemProps> = ({
  title,
  description,
  url,
}) => {
  return (
    <Box
      as="a"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      display="flex"
      flexDir="column"
      px="13px"
      py="8px"
      borderRadius="lg"
      bg="transparent"
      position="relative"
      _hover={{
        bg: "blackAlpha.50",
        "& .hover-image": {
          display: "block",
        },
        "& .hover-button": {
          display: "flex",
        },
      }}
      transition="background-color 0.2s"
      cursor="pointer"
    >
      <ArticleItemContent title={title} description={description} url={url} />
    </Box>
  );
};

const WebArticlesHeader: React.FC<{
  resultsCount: number;
  onHide: () => void;
  isCollapsed: boolean;
}> = ({ resultsCount, onHide, isCollapsed }) => (
  <Flex
    gap={5}
    justify="space-between"
    align="center"
    w="full"
    wrap="wrap"
    pl="13px"
  >
    <Flex gap={2} color="black" align="center" justify="center">
      <Icon as={BsGlobe} w="12px" h="12px" />
      <Flex alignItems="center" gap="4px">
        <Text as="span" fontWeight="bold">
          {resultsCount}
        </Text>
        <Text as="span" fontWeight="medium">
          Related Web articles found
        </Text>
      </Flex>
    </Flex>
    <Button
      variant="ghost"
      size="xs"
      rightIcon={
        <Image
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/938af7578e9431e07e9f39f64a86c8a86e3943a4c3375f6dcae28f180c9004b2?placeholderIfAbsent=true&apiKey=ffbb3f264c66444e838eafc91f533e20"
          alt=""
          w="12px"
          aspectRatio="1"
          transform={isCollapsed ? "rotate(180deg)" : "none"}
          transition="transform 0.2s"
        />
      }
      fontWeight="semibold"
      color="gray.900"
      onClick={onHide}
    >
      {isCollapsed ? "Show" : "Hide"}
    </Button>
  </Flex>
);

export const WebArticles: React.FC<{
  results: SearchResultsWidgetData["results"];
}> = ({ results }) => {
  console.log("WebArticles results :>> ", results);
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Box maxW="530px">
      <VStack
        py={"10px"}
        w="full"
        bg="white"
        borderRadius="none"
        align="stretch"
      >
        <Box px="4px" w="full" fontSize="12px">
          <WebArticlesHeader
            resultsCount={results.length}
            onHide={() => setIsCollapsed(!isCollapsed)}
            isCollapsed={isCollapsed}
          />
          {!isCollapsed &&
            results.map((result, index) => (
              <ArticleItem
                key={index}
                title={result.title}
                description={result.snippet}
                url={result.url}
              />
            ))}
        </Box>
      </VStack>
    </Box>
  );
};

export default function SearchResultsWidget({
  data,
}: WidgetComponentProps<SearchResultsWidgetData>) {
  console.log("SearchResultsWidget data :>> ", data);
  return <WebArticles results={data.results} />;
}
