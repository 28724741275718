import { Icon } from "@chakra-ui/react";
import React from "react";

const HeaderBoxMenuIcon = (props) => {
  return (
    <Icon fill="black" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <rect x="10" width="6" height="6" />
      <rect width="6" height="6" />
      <rect x="10" y="10" width="6" height="6" />
      <rect y="10" width="6" height="6" />
    </Icon>
  );
};

export default HeaderBoxMenuIcon;
