import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  Icon,
  // InputGroup,
  // InputRightElement,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
// import SearchIconComponent from "../Components/Icons/SearchIconComponent";
import { FaRegFolderClosed } from "react-icons/fa6";

import { IoGrid } from "react-icons/io5";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { ProjectDoc } from "shared/projectTypes";
import { UserDoc } from "shared/userTypes";
import { WorkspaceDoc } from "shared/workspaceTypes";
import useAiModules from "src/hooks/useAiModules";
import { useActiveWorkspaceId } from "src/stores/userWorkspaces";
import { useAuth, UserWithEmail } from "../../Auth/AuthProvider";
import ProjectModal from "../../Components/Elements/NewProjectModal";
import { AiModulesList } from "../../Components/Home/AIModules/AiModulesList";
import {
  HomeLibraryBttn,
  HomeTabSwitchBttn,
} from "../../Components/Home/HomeTabSwitchBttn";
import { NewModuleButton } from "../../Components/Home/NewModuleButton";
import NewProjectIconComponent from "../../Components/Icons/NewProjectIconComponent";
import PinIcon from "../../Components/Icons/PinIcons";
import Layout from "../../Components/Layout";
import { RequestModuleBttn } from "../../Components/RequestModule/RequestModuleBttn";
import { UserHomePageTour } from "../../Components/Tour/UserHomePageTour";
import { ArchiveProjectListCard } from "../../Components/UserPage/ArchiveProjectListCard";
import { ProjectsList } from "../../Components/UserPage/ProjectsList";
import { InstallExtensionNotice } from "../../Components/Utils/InstallExtensionNotice";
import { getCustomizationData } from "../../Components/Utils/customizationUtils";
import { useAssistantContext } from "../../Context/AssistantContext";
import {
  ProjectWithPermission,
  useProjectContext,
} from "../../Context/ProjectContext";
import { createProject } from "../../db/project";
import { useExtensionPresence } from "../../hooks/useExtensionPresent";
import useNotification from "../../hooks/useNotification";
import "./userpage.module.css";

const customization = getCustomizationData();

/** The gap between project cards. */
export const CARD_GAP = 16;
/** The width of each project card. */
export const CARD_WIDTH = 164;

const ProjectsSection = ({
  pinnedProjects,
  userDoc,
  unpinnedProjects,
  projectsFromOtherWorkspace,
  showNoProjectsText,
}: {
  pinnedProjects: ProjectWithPermission[];
  userDoc: UserDoc;
  unpinnedProjects: ProjectWithPermission[];
  projectsFromOtherWorkspace: ProjectWithPermission[];
  showNoProjectsText: boolean;
}) => {
  return (
    <Flex flexDir="column" gap={4}>
      {pinnedProjects.length > 0 && (
        <ProjectsList
          projects={pinnedProjects}
          currentlyPinnedProjects={userDoc.pinnedProjects || []}
          rowTitle="Pinned projects"
          icon={<PinIcon color="gray" boxSize="15px" />}
          disableShowMore={true}
        />
      )}

      <ProjectsList
        rowTitle="Workspace projects"
        projects={unpinnedProjects}
        currentlyPinnedProjects={userDoc.pinnedProjects || []}
        showNoProjectsText={showNoProjectsText}
        showAllProjectsButtonText="workspace projects"
      />

      {projectsFromOtherWorkspace.length > 0 && (
        <ProjectsList
          rowTitle="Shared from other workspaces"
          projects={projectsFromOtherWorkspace}
          currentlyPinnedProjects={userDoc.pinnedProjects || []}
          showAllProjectsButtonText="shared projects from other workspaces"
        />
      )}
    </Flex>
  );
};

const ArchivedProjectsSection = ({
  sortedAndArchivedProjects,
  setArchivedOpened,
  archivedOpened,
}) => {
  return (
    <Box px={4} py={2} bg="white" my="24px">
      <Flex justify="space-between" my={1}>
        <Text fontSize="12px" fontWeight="500">
          Archive ({sortedAndArchivedProjects.length})
        </Text>
        <Icon
          cursor="pointer"
          onClick={() => {
            setArchivedOpened(!archivedOpened);
          }}
          as={archivedOpened ? ChevronUpIcon : ChevronDownIcon}
          fontWeight={500}
        />
      </Flex>
      {archivedOpened &&
        sortedAndArchivedProjects.map((projectData) => (
          <ArchiveProjectListCard
            key={projectData.uid}
            projectName={projectData.name}
            projectId={projectData.uid}
            createdBy={projectData.createdBy}
            authorDisplayName={projectData.authorDisplayName}
            authorPhotoURL={projectData.authorPhotoURL}
          />
        ))}
    </Box>
  );
};

const ArchivedProjectsPlaceholder = () => {
  return (
    <Box px={4} py={2} bg="white" my="24px">
      <Flex justify="space-between" my={1}>
        <Text fontSize="12px" fontWeight="500">
          Archive (0)
        </Text>
      </Flex>
    </Box>
  );
};

const createNewProject = async ({
  projectTitle,
  sortedByTimeProjects,
  notify,
  setIsSaving,
  setShowInputModal,
  currentUser,
  activeWorkspaceId,
}: {
  projectTitle: ProjectDoc["name"];
  sortedByTimeProjects: ProjectDoc[];
  notify: ReturnType<typeof useNotification>["notify"];
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  setShowInputModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: UserWithEmail;
  activeWorkspaceId: WorkspaceDoc["uid"];
}) => {
  //check if project name already exist in the project list
  const projectExists = sortedByTimeProjects.find(
    (project) => project.name === projectTitle
  );
  if (projectExists) {
    notify({
      title: "Project name already exists",
      status: "error",
    });
    return;
  }
  if (projectTitle) {
    setIsSaving(true);
    const createdAt = Date.now(); //new Date().toISOString();
    const createdBy = currentUser.uid;
    const projectId = `${Date.now()}${createdBy}`;
    const payload = {
      uid: projectId,
      createdAt,
      createdBy,
      name: projectTitle,
      workspace: {
        uid: activeWorkspaceId,
        publicPermission: "none" as ProjectDoc["workspace"]["publicPermission"],
      },
    };
    await createProject({
      projectId,
      payload,
      userId: currentUser.uid,
      userEmail: currentUser.email,
    });
    notify({
      title: "Project created successfully",
      status: "success",
    });
    setShowInputModal(false);
    setIsSaving(false);
  }
};

export const TABS = {
  PROJECTS: {
    id: "projects",
    icon: <FaRegFolderClosed />,
  },
  AI_MODULES: { id: "custom-ai", icon: <IoGrid /> },
  AI_MODULES_ADMIN: { id: "custom-ai-admin", icon: <IoGrid /> },
};

const UserHomePage = () => {
  const {
    sortedByTimeProjects: sortedByTimeProjectsWithPermission,
    sortedAndArchivedProjects: sortedAndArchivedProjectsWithPermission,
    activeProjectsWithoutArchived: activeProjectsWithoutArchivedWithPermission,
  } = useProjectContext();

  const sortedByTimeProjects = sortedByTimeProjectsWithPermission.map(
    ({ data }) => data
  );
  const sortedAndArchivedProjects = sortedAndArchivedProjectsWithPermission.map(
    ({ data }) => data
  );
  const activeProjectsWithoutArchived =
    activeProjectsWithoutArchivedWithPermission.map(({ data }) => data);

  const activeWorkspaceId = useActiveWorkspaceId();
  const { currentUser, userDoc, isAiModulesAdmin } = useAuth();
  const [archivedOpened, setArchivedOpened] = useState(false);
  const [projectContainerWidth, setProjectContainerWidth] = useState(0);
  const [showInputModal, setShowInputModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { notify } = useNotification();
  const { modules } = useAiModules();

  const { extensionPresent, loading: extensionPresentLoading } =
    useExtensionPresence();
  const { setAssistantIsOpen } = useAssistantContext();

  useEffect(() => {
    setAssistantIsOpen(false);
  }, []);

  const pinnedProjects = activeProjectsWithoutArchivedWithPermission.filter(
    ({ data }) => userDoc.pinnedProjects?.includes(data.uid)
  );

  const getWorkspaceProjects = useCallback(() => {
    return activeProjectsWithoutArchivedWithPermission.filter(
      ({ data }) => data.workspace?.uid === activeWorkspaceId
    );
  }, [activeProjectsWithoutArchivedWithPermission, activeWorkspaceId]);

  // add usecallback for the following
  const getUnpinnedWorkspaceProjects = useCallback(() => {
    return getWorkspaceProjects().filter(
      ({ data }) => !userDoc.pinnedProjects?.includes(data.uid)
    );
  }, [getWorkspaceProjects, userDoc.pinnedProjects]);

  const getProjectsFromOtherWorkspace = useCallback(() => {
    return activeProjectsWithoutArchivedWithPermission.filter(
      ({ data }) =>
        data.workspace?.uid != activeWorkspaceId &&
        !userDoc.pinnedProjects?.includes(data.uid)
    );
  }, [
    activeProjectsWithoutArchivedWithPermission,
    activeWorkspaceId,
    userDoc.pinnedProjects,
  ]);

  const unpinnedWorkspaceProjects = getUnpinnedWorkspaceProjects();

  const projectsFromOtherWorkspace = getProjectsFromOtherWorkspace();

  const history = useHistory();

  const searchParams = history.location.search;
  const currentSearchParams = new URLSearchParams(searchParams);
  const selectedTabType = currentSearchParams.get("tab");

  if (!currentUser.emailVerified) {
    history.replace("/verifyemail");
  }

  // if came from onboarding, do not redirect back to onboarding;
  // Otherwise, based on the onboarding status in the userDoc, redirect to onboarding.
  // This is to prevent the user from redirecting immediately back to onboarding
  // before userDoc has the chance to update.
  const queryParams = new URLSearchParams(location.search);
  const fromOnboarding = queryParams.get("fromOnboarding");
  if (!fromOnboarding && !userDoc.onboardingProcess?.completed) {
    history.replace("/welcome");
  }

  let currentTimeZone = "Hello";
  const currentHour = new Date().getHours();
  if (currentHour < 12) {
    currentTimeZone = "Good Morning,";
  } else if (currentHour >= 12 && currentHour < 16) {
    currentTimeZone = "Good Afternoon,";
  } else if (currentHour >= 16) {
    currentTimeZone = "Good Evening,";
  }

  // The following block is responsible for handling number of project cards to show
  // depending on the screen width; in order for all components to be aligned (Search bar, and Archive bar),
  // we calculate how many project cards are the closest to the half of the screen, and use this
  // value to set the width of the container. We also calculate the number of rows
  // that can be shown, and use this value to determine whether to show the "Show All"
  // button or not.

  // Function to handle resize and state changes
  const handleUpdates = () => {
    const screenWidth = window.innerWidth;
    const numberOfCards = Math.round(screenWidth / 2 / (CARD_WIDTH + CARD_GAP));
    const spaceNeeded =
      numberOfCards * CARD_WIDTH + (numberOfCards - 1) * CARD_GAP;
    setProjectContainerWidth(spaceNeeded);
  };

  // useEffect for handling screen resize
  useEffect(() => {
    const handleResize = () => {
      handleUpdates();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeProjectsWithoutArchived]);

  // useEffect for handling filteredProjects.length and visibleRows changes
  useEffect(() => {
    handleUpdates();
  }, [userDoc.pinnedProjects?.length, activeProjectsWithoutArchived.length]);

  if (!modules) return null;
  const { ownedModules, privateModules, userPublicModules } = modules;

  if (!activeWorkspaceId) {
    return <Redirect to="/" />;
  }

  return (
    <Layout>
      <RequestModuleBttn />
      <Box>
        <Flex direction="row" justify="space-between" display={"flex"}>
          <Box color="black" w={`${projectContainerWidth}px`}>
            <Stack
              spacing={2}
              direction="row"
              align="center"
              pt="20px"
              pb="15px"
            >
              <HStack>
                <Text fontSize="16px" fontWeight="600" color="primary">
                  {currentTimeZone}{" "}
                  <span style={{ color: "#59808D" }}>
                    {currentUser.displayName}!
                  </span>
                </Text>
              </HStack>
              <Spacer />
            </Stack>
            <Box>
              {!extensionPresent && !extensionPresentLoading && (
                <InstallExtensionNotice />
              )}
              <Flex pt={4} />
              <Flex pr={2} h="24px">
                <Flex gap="10px" align={"center"}>
                  <HomeTabSwitchBttn
                    text="Projects"
                    totalNum={activeProjectsWithoutArchived.length}
                    type={TABS.PROJECTS}
                  />
                  <HomeTabSwitchBttn
                    text="AI Modules"
                    totalNum={
                      [...ownedModules, ...privateModules, ...userPublicModules]
                        .length
                    }
                    type={TABS.AI_MODULES}
                  />
                  {isAiModulesAdmin && (
                    <HomeTabSwitchBttn
                      text="AI Modules Admin"
                      type={TABS.AI_MODULES_ADMIN}
                    />
                  )}
                </Flex>
                <Spacer />
                <Flex gap="12px">
                  {selectedTabType === TABS.AI_MODULES_ADMIN.id && (
                    <>
                      <NewModuleButton />
                      <HomeLibraryBttn />
                    </>
                  )}
                  {selectedTabType === TABS.PROJECTS.id && (
                    <HStack
                      id="newProjectBttnTour"
                      cursor="pointer"
                      onClick={() => setShowInputModal(true)}
                    >
                      <Text fontSize="12px">New Project</Text>
                      <Icon as={NewProjectIconComponent} />
                    </HStack>
                  )}
                  {selectedTabType === TABS.AI_MODULES.id && (
                    <>
                      {customization.aiModules.showSimpleEditor && (
                        <NewModuleButton />
                      )}
                      {!customization.aiModules.hideLibrary && (
                        <HomeLibraryBttn />
                      )}
                    </>
                  )}
                </Flex>
              </Flex>
              {selectedTabType === TABS.PROJECTS.id && (
                <Box>
                  <ProjectsSection
                    pinnedProjects={pinnedProjects}
                    userDoc={userDoc}
                    unpinnedProjects={unpinnedWorkspaceProjects}
                    projectsFromOtherWorkspace={projectsFromOtherWorkspace}
                    showNoProjectsText={getWorkspaceProjects().length === 0}
                  />
                  {sortedAndArchivedProjects.length > 0 ? (
                    <ArchivedProjectsSection
                      sortedAndArchivedProjects={sortedAndArchivedProjects}
                      setArchivedOpened={setArchivedOpened}
                      archivedOpened={archivedOpened}
                    />
                  ) : (
                    <ArchivedProjectsPlaceholder />
                  )}
                  {activeProjectsWithoutArchived.length > 0 && (
                    <UserHomePageTour />
                  )}
                </Box>
              )}
              <Box mt="30px">
                {selectedTabType === TABS.AI_MODULES.id && <AiModulesList />}
                {selectedTabType === TABS.AI_MODULES_ADMIN.id && (
                  <AiModulesList action="EDIT" />
                )}
              </Box>
            </Box>

            {showInputModal && (
              <ProjectModal
                projectName=""
                isOpen={showInputModal}
                onClose={() => setShowInputModal(false)}
                onSubmit={(projectTitle) =>
                  createNewProject({
                    projectTitle,
                    sortedByTimeProjects,
                    notify,
                    setIsSaving,
                    setShowInputModal,
                    currentUser,
                    activeWorkspaceId,
                  })
                }
                isSaving={isSaving}
                title="Add new project"
                type="create"
              />
            )}
          </Box>
        </Flex>
      </Box>
    </Layout>
  );
};

export default UserHomePage;
