import {
  collection,
  collectionGroup,
  CollectionReference,
  doc,
  DocumentReference,
  FirestoreDataConverter,
  getDocs,
} from "firebase/firestore";
import { AiModuleDoc, AiModulePermission } from "shared/aiModule";
import { COL_AI_MODULE_PERMISSIONS, COL_AI_MODULES } from "shared/constants";
import { MultistepModuleDefinition } from "src/Components/AiModuleEditor/multistepAiModuleTypes";
import { db } from "src/firebase";

const aiModulePermissionConverter: FirestoreDataConverter<AiModulePermission> =
  {
    toFirestore: (permission: AiModulePermission) => permission,
    fromFirestore: (snapshot) => snapshot.data() as AiModulePermission,
  };

export const aiModuleConverter: FirestoreDataConverter<AiModule> = {
  toFirestore: (aiModule: AiModule) => aiModule,
  fromFirestore: (snapshot) => snapshot.data() as AiModule,
};

export const presentationConverter: FirestoreDataConverter<Presentation> = {
  toFirestore: (presentation: Presentation) => presentation,
  fromFirestore: (snapshot) => snapshot.data() as Presentation,
};

export const definitionConverter: FirestoreDataConverter<MultistepModuleDefinition> =
  {
    toFirestore: (definition: MultistepModuleDefinition) => definition,
    fromFirestore: (snapshot) => snapshot.data() as MultistepModuleDefinition,
  };
export const instructionConverter: FirestoreDataConverter<Instruction> = {
  toFirestore: (instruction: Instruction) => instruction,
  fromFirestore: (snapshot) => snapshot.data() as Instruction,
};

export const aiModuleDocConverter: FirestoreDataConverter<AiModuleDoc> = {
  toFirestore: (module: AiModuleDoc) => module,
  fromFirestore: (snapshot) => snapshot.data() as AiModuleDoc,
};

export interface AiModule {
  title: string;
  description: string;
  activeVersionId: string;
  versions: CollectionReference<Version>;
  nVersions: number;
  aiModulePermissions: CollectionReference<AiModulePermission>;
}
// Version document

export interface Version {
  versionNumber: number;
  createdAt: number;
  createdBy: string;
}
// Presentation data

export interface Presentation {
  title: string;
  description: string;
  userMessage: string;
  variables?: { [key: string]: { description: string; optional: boolean } };
  /** Module configuration version. It is missing on the simple modules. */
  api_version?: number;
}
// Instruction data

export interface Instruction {
  systemMessage: string;
  temperature: number;
  fullTextMode: boolean;
}
// ModuleData collection documents
type ModuleData = Presentation | Instruction | MultistepModuleDefinition;

export const aiModulesCollection = collection(db, "aiModules").withConverter(
  aiModuleConverter
);

export const versionsSubCollection = (
  moduleId: string
): CollectionReference<Version> =>
  collection(
    doc(aiModulesCollection, moduleId),
    "versions"
  ) as CollectionReference<Version>;

export const permissionsSubCollection = (
  moduleId: string
): CollectionReference<AiModulePermission> =>
  collection(
    doc(aiModulesCollection, moduleId),
    "aiModulePermissions"
  ) as CollectionReference<AiModulePermission>;

export const moduleDataSubCollection = (
  moduleId: string,
  versionId: string,
  docType: "presentation" | "instruction" | "definition"
): DocumentReference<ModuleData> =>
  doc(
    collection(doc(versionsSubCollection(moduleId), versionId), "moduleData"),
    docType
  ) as DocumentReference<ModuleData>;

export const getModulePresentationDoc = (
  moduleId: string,
  versionId: string
): DocumentReference<Presentation> =>
  moduleDataSubCollection(moduleId, versionId, "presentation").withConverter(
    presentationConverter
  );

export const getModuleInstructionDoc = (
  moduleId: string,
  versionId: string
): DocumentReference<Instruction> =>
  moduleDataSubCollection(moduleId, versionId, "instruction").withConverter(
    instructionConverter
  );

export const getModuleDefinitionDoc = (
  moduleId: string,
  versionId: string
): DocumentReference<MultistepModuleDefinition> =>
  moduleDataSubCollection(moduleId, versionId, "definition").withConverter(
    definitionConverter
  );
// Example Functions

export async function getModulePermissions(moduleId: string) {
  const permissionsSnapshot = await getDocs(permissionsSubCollection(moduleId));
  return permissionsSnapshot.docs.map(
    (doc) => doc.data() as AiModulePermission
  );
}

export const getAiModulePermissionsCollection = (moduleId: string) =>
  collection(
    doc(aiModulesCollection, moduleId),
    COL_AI_MODULE_PERMISSIONS
  ).withConverter(aiModulePermissionConverter);

export const aiModulePermissionsCollectionGroup = collectionGroup(
  db,
  COL_AI_MODULE_PERMISSIONS
).withConverter(aiModulePermissionConverter);

export const aiModuleDocCollection = collection(
  db,
  COL_AI_MODULES
).withConverter(aiModuleDocConverter); // Main aiModules collection
