import { Flex, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { SelectedArticle } from "shared/conversation";
import {
  isAiModuleInvocationMessage,
  isSimpleChatMessage,
  isTemplateMessage,
} from "shared/conversationUtils";
import { ChatMessageForRendering } from "src/Context/AssistantContext";
import {
  DisplaySingleArticle,
  MultiDocSelectMenuOnChat,
} from "../../Utils/assistantMessageDisplay";
import style from "../../react-markdown.module.css";
import { CustomAiDocQueryMessage } from "./CustomAIDocQueryMessage";
import { MessageIsRestrictedToChatIndicator } from "./MessageIsRestrictedToChatIndicator";
import TemplateMessageType from "./TemplateMessageType";

const MessageTypeIndicator = ({
  message,
}: {
  message: ChatMessageForRendering;
  title?: string;
}) => {
  if (isAiModuleInvocationMessage(message)) {
    return <CustomAiDocQueryMessage title={message.moduleTitle} />;
  }

  if (isTemplateMessage(message)) {
    return <TemplateMessageType templateTitle={message.title} />;
  }

  return null;
};

const ArticleSelectedIndicator = ({
  selectedArticles,
  toggleSelectedArticleList,
  showSelectedArticleList,
}: {
  selectedArticles?: SelectedArticle[];
  toggleSelectedArticleList: () => void;
  showSelectedArticleList: boolean;
}) => {
  if (!selectedArticles || selectedArticles.length === 0) {
    return null;
  } else if (selectedArticles.length === 1) {
    return <DisplaySingleArticle selectedArticle={selectedArticles[0]} />;
  } else {
    return (
      <MultiDocSelectMenuOnChat
        toggleSelectedArticleList={toggleSelectedArticleList}
        selectedArticles={selectedArticles}
        showSelectedArticleList={showSelectedArticleList}
      />
    );
  }
};

export const UserMessage = ({
  message,
}: {
  message: ChatMessageForRendering;
}) => {
  const [showSelectedArticleList, setShowSelectedArticleList] = useState(false);
  const toggleSelectedArticleList = () => {
    setShowSelectedArticleList(!showSelectedArticleList);
  };

  return (
    <Flex
      borderRadius="lg"
      gap="8px"
      bg="#F1F1F1"
      align="top"
      pl="1"
      color="#191D30 !important"
    >
      <VStack align="start" gap={1} w="100%">
        {message.inputValue ? (
          <ReactMarkdown className={style.reactMarkDown}>
            {message.inputValue}
          </ReactMarkdown>
        ) : null}
        <MessageTypeIndicator message={message} />
        {isSimpleChatMessage(message) && message.useOnlyChat && (
          <MessageIsRestrictedToChatIndicator />
        )}
        {!isTemplateMessage(message) ? (
          <ArticleSelectedIndicator
            selectedArticles={message.selectedArticles}
            toggleSelectedArticleList={toggleSelectedArticleList}
            showSelectedArticleList={showSelectedArticleList}
          />
        ) : null}
      </VStack>
    </Flex>
  );
};
