import { useEffect, useRef } from "react";

export const useScrollToRef = (
  changeTrigger: unknown,
  behavior: ScrollBehavior = "smooth"
) => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    ref.current?.scrollIntoView({ behavior });
  };

  useEffect(scrollToBottom, [changeTrigger]);

  return { endRef: ref };
};
