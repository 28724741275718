import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { BsBookmark } from "react-icons/bs";
import { MdDragIndicator } from "react-icons/md";
import { useOrganizerContext } from "../../../Context/OrganizerContext";
import { DeleteAlert } from "../../Elements/DeleteAlert";
import EditableText from "../../General/EditableText";
import BinIcon from "../../Icons/BinIcon";
import SmartSectionCard from "./SmartSectionCard";

const SmartDragSection = ({ title, cards, id, index }) => {
  const {
    deleteTemplateSection,
    updateSectionTitle,
    activeSections,
    setActiveSections,
    onMouseUp,
    onMouseDown,
  } = useOrganizerContext();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const handleSectionInput = (newValue) => {
    updateSectionTitle(id, newValue);
  };

  return (
    <>
      <Draggable key={id} draggableId={id} index={index}>
        {(provided) => (
          <Flex
            color="primary"
            ref={provided.innerRef}
            {...provided.draggableProps}
            direction={"column"}
            gap="8px"
          >
            <Flex
              flex="1"
              gap="8px"
              alignItems="center"
              flexWrap="wrap"
              bg="white"
              borderRadius="md"
            >
              <HStack flex="1" w="full">
                <IconButton
                  variant="ghost"
                  aria-label="delete"
                  icon={<MdDragIndicator />}
                  color="#59808D"
                  fontSize="20"
                  {...provided.dragHandleProps}
                  onMouseDown={() => onMouseDown(id)}
                  onMouseUp={onMouseUp}
                />
                <HStack flex="1" w="full">
                  <Text fontSize="12px" fontWeight="medium">
                    {`${index + 1}.`}{" "}
                  </Text>
                  <Box w="full">
                    <Flex alignItems={"center"}>
                      <EditableText
                        text={title}
                        submitHandler={(value) => handleSectionInput(value)}
                        inputProps={{
                          fontSize: "12px",
                          fontWeight: "medium",
                        }}
                      />
                      {/* <PercentWordsUsed data={cards} /> */}
                    </Flex>
                  </Box>
                </HStack>
              </HStack>
              <Flex direction="row" pr={2} alignItems={"center"}>
                {/* <IconButton
                  variant="ghost"
                  bg="white"
                  aria-label="delete"
                  icon={<MdOutlineOfflineBolt />}
                  color="#757575"
                  fontSize="18"
                  onClick={() => {}}
                /> */}
                <IconButton
                  variant="ghost"
                  bg="white"
                  aria-label="delete"
                  icon={<BinIcon opacity={0.54} />}
                  color="#757575"
                  fontSize="18"
                  onClick={() => onOpen()}
                />
                <Text fontSize="10px" fontWeight="medium">
                  {cards.length > 0 ? `(${cards.length})` : ""}
                </Text>
                {activeSections.includes(id) ? (
                  <Icon
                    onClick={() =>
                      setActiveSections(
                        activeSections.filter((item) => item !== id)
                      )
                    }
                    as={ChevronUpIcon}
                    fontSize="24px"
                    strokeWidth={1}
                    cursor="pointer"
                  />
                ) : (
                  <Icon
                    onClick={() => setActiveSections([...activeSections, id])}
                    as={ChevronDownIcon}
                    fontSize="24px"
                    strokeWidth={1}
                    cursor="pointer"
                  />
                )}
              </Flex>
            </Flex>
            {activeSections.includes(id) && (
              <Droppable key={id} droppableId={id}>
                {(provided) => (
                  <Flex ref={provided.innerRef} gap="8px" direction={"column"}>
                    {cards &&
                      cards.map((card, index) => (
                        <React.Fragment key={card.uid}>
                          {card != 0 && (
                            <SmartSectionCard
                              card={card}
                              id={id}
                              index={index}
                              key={card.uid}
                              sectionName={title}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    {cards && cards.length < 1 && (
                      <Flex
                        mt="2"
                        rounded="md"
                        border="1px dashed"
                        borderColor="#c2c2c2"
                        id={id}
                        backgroundColor="white"
                        w="full"
                        h="80px"
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Text
                          opacity={0.54}
                          fontSize="10px"
                          lineHeight={1.1}
                          textAlign="center"
                          fontWeight="normal"
                          color="#757575"
                          style={{ textWrap: "balance" }}
                          maxW="60%"
                        >
                          Highlight and add insights from assistant answers, PDF
                          Viewer, or drag and drop from{" "}
                          <Icon
                            as={BsBookmark}
                            viewBox="8px"
                            alignSelf={"center"}
                            justifySelf={"center"}
                          />{" "}
                          Saved Insights.
                        </Text>
                      </Flex>
                    )}
                    {provided.placeholder}
                  </Flex>
                )}
              </Droppable>
            )}
          </Flex>
        )}
      </Draggable>
      <DeleteAlert
        isOpen={isOpen}
        onClose={onClose}
        action={() => deleteTemplateSection(id)}
        title="Delete Section"
        body="Are you sure you want to delete this section?"
      />
    </>
  );
};
export default SmartDragSection;
