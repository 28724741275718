import { HStack, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useAuth } from "../../Auth/AuthProvider";
import { useTemplateContext } from "../../Context/TemplateContext";
import OrganizerPreviewModal from "../Elements/OrganizerPreviewModal";
import EditableText from "../General/EditableText";
import AddIconComponent from "../Icons/AddIconComponent";
import PreviewIconComponent from "../Icons/PreviewIconComponent";
import HeaderTab from "../SavedContents/HeaderTab";
import { getCustomizationData } from "../Utils/customizationUtils";
import AssistantHeaderWrapper from "./AssistantHeaderWrapper";
import FillTemplate from "./FillTemplate";
import { FILL_TEMPLATE_STATUS } from "./SmartTemplate/AssistantSmartTemplate";

type Mode = "templates" | "history" | "customAi";

const customization = getCustomizationData();
const GeneralTabAssistantHeader = ({
  title,
  mode,
}: {
  title: string;
  mode: Mode;
}) => {
  const { isPowerUser, currentUser } = useAuth();
  const currentUserId = currentUser.uid;
  const {
    currentTemplate,
    handleBackNavigation,
    createNewTemplate,
    updateTemplateName,
  } = useTemplateContext();
  const fillTemplateIsLoading =
    currentTemplate?.fillTemplateStatus?.status ===
    FILL_TEMPLATE_STATUS.LOADING;
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();
  return (
    <AssistantHeaderWrapper
      backButtonHandler={handleBackNavigation}
      title={
        currentTemplate ? (
          <EditableText
            text={currentTemplate.name}
            submitHandler={(value) =>
              updateTemplateName(value, currentTemplate.id)
            }
            inputProps={{
              fontSize: "xs",
              color: "primary",
              fontWeight: "medium",
            }}
          />
        ) : (
          title
        )
      }
    >
      {currentTemplate ? (
        <HStack gap={6}>
          {isPowerUser || customization.template.enableFillTemplate ? (
            <FillTemplate />
          ) : null}
          <HeaderTab
            handler={() => openModal()}
            icon={PreviewIconComponent}
            title="Preview"
            boxSize={5}
            fontSize="xs"
            color="#707070"
            display={fillTemplateIsLoading ? "none" : "flex"}
            toBeHighlighted={false}
            iconMt={0}
            infoTooltipType={null}
            isCompact={false}
            disabled={false}
            disabledTooltip=""
          />
        </HStack>
      ) : (
        mode === "templates" && (
          <HeaderTab
            color="#707070"
            icon={AddIconComponent}
            title={`New ${customization.template.name}`}
            boxSize={5}
            fontSize="xs"
            handler={() => {
              createNewTemplate(currentUserId);
            }}
            toBeHighlighted={false}
            iconMt={0}
            infoTooltipType={null}
            isCompact={false}
            disabled={false}
            disabledTooltip=""
          />
        )
      )}
      <OrganizerPreviewModal isOpen={isModalOpen} onClose={closeModal} />
    </AssistantHeaderWrapper>
  );
};

export default GeneralTabAssistantHeader;
