import { Box, HStack } from "@chakra-ui/react";
import merge from "deepmerge";
import { defaultSchema } from "hast-util-sanitize";
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import { ArticleDoc } from "shared/articleTypes";
import { SimpleChatMessageData, WidgetMessageData } from "shared/conversation";
import { isWidgetMessage } from "shared/conversationUtils";
import { MessageForRendering } from "src/Context/AssistantContext";
import { withSaveSelectionToInsights } from "../../HigherOrderComponents/withSaveSelectionToInsights";
import style from "../../react-markdown.module.css";
import ReferenceBubble from "../AssistantReferenceBubble";
import CitationChips from "./CitationChips";
import { CopyMessage } from "./CopyMessage";
// import "./MessageCitation.css";
import { MessageFeedback } from "./MessageFeedback";
import {
  makeArticleIdToReferenceNumberMap,
  makeChunkToArticleReferenceNumberMap,
} from "./messageUtils";
import { MessageWrapper } from "./MessageWrapper";
import { UserMessage } from "./UserMessage";

const customSchema = merge(defaultSchema, {
  attributes: {
    "*": ["style", "figure", "img", "figcaption", "className"],
  },
});

/**
 * Messages with citations. The content of the message from LLM includes citation of chunks in the form of
 * [1](#1), [2](#2), etc. These references can be found inside `referenceData` array or maps. Each item
 * in this array has a property `referenceId` which is the number inside the parenthesis.
 *
 * However, the final result should be citing articles, not chunks. In order to do that, we need to
 * process the citations. In particular, if two citations [1](#1) and [2](#2) refer to the same article
 * (chunks have the same articleId), we need to change both of them to be displayed as [1](#1), where
 * the number inside the parenthesis is the number of the article in the final reference list. But the tooltip
 * should still point to the original chunk, which can be different for each citation.
 *
 * The following steps need to be taken during the preprocessing:
 *
 * 1. Create a map of articleId: numeric reference. This map will be used to determine which number to display
 * in the citation bubble. Filter out articles which are not cited. Each article should have a unique number.
 * 2. Create a map of chunk ids to article ids. This map will be used to determine which number to display
 * in the citation bubble.
 * 3. Render the message content, replacing every link with a span containing the reference number obtained
 * from the chunkId to articleId map. If the reference data for a particular chunk is present, replace the
 * href attribute in the link with the corresponding articleId.
 * 4. Create a CitationChips component that generates an array of chips. Each chip contains the reference
 * number along with the article title. These chips link to the corresponding article URLs and are displayed
 * below the message.
 *
 * The Message component is then responsible for rendering each message along with its associated CitationChips
 * (if any). It creates the required maps using the helper functions and passes them down to the CitationChips
 * and link renderers.
 * In the end, we have a system where every article that is cited in a message has a unique numeric identifier.
 * This identifier is displayed in the citation bubble of every chunk that comes from that article.
 * The tooltip for each citation bubble still points to the original chunk, which allows for distinct tooltips
 * for each citation even if they refer to the same article. Furthermore, below each message, we display
 * chips for every cited article, making it easy for users to follow the citation to the original source.
 */
const MessageToRender = ({
  msg,
  renderers,
}: {
  msg: MessageForRendering;
  renderers: Record<string, React.ComponentType<unknown>>;
}) => {
  if (isWidgetMessage(msg)) {
    return null;
  }
  if (msg.role === "user") {
    return <UserMessage message={msg} />;
  } else {
    // Append an inline HTML image/icon at the end of the markdown content
    const modifiedContent = `${msg.content} ${
      msg.continue
        ? '<span class="loadingDot"></span><span class="loadingDot"></span><span class="loadingDot"></span>'
        : ""
    }`;

    return (
      <div className={style.reactMarkDown}>
        <ReactMarkdown
          components={renderers}
          rehypePlugins={[rehypeRaw, [rehypeSanitize, customSchema]]}
        >
          {modifiedContent}
        </ReactMarkdown>
      </div>
    );
  }
};

/**
 *
 * Apply ad-hoc modifications to the message content.
 *
 * 1. Change wrong citation patterns [#1](#1] and [#1][#1) to [#1](#1).
 *
 * @param {string} content Message content
 */
const correctMessageContent = (content) => {
  let newContent = content;

  // [1](#1]
  const regex = /\[(\d+)\]\(#(\d+)\]/g;
  newContent = newContent.replace(regex, (match, p1, p2) => {
    if (p1 === p2) {
      return `[${p1}](#${p2})`;
    }
    return match;
  });

  // [1][#1)
  const regex2 = /\[(\d+)\]\[#(\d+)\]/g;
  newContent = newContent.replace(regex2, (match, p1, p2) => {
    if (p1 === p2) {
      return `[${p1}](#${p2})`;
    }
    return match;
  });

  return newContent;
};

type ChunkInfo = {
  articleId: ArticleDoc["uid"];
  articleTitle: ArticleDoc["title"];
  articleUrl?: ArticleDoc["url"];
  chunkId: string;
  content: SimpleChatMessageData["content"];
  image: boolean;
  referenceString: string;
};

/**
 * Renders a message component.
 */
const Message = ({
  message,
  index,
  handleMouseUp,
  setMsgFeedback,
}: {
  /** Message to render */
  message: Exclude<MessageForRendering, WidgetMessageData>;
  /** Index of the message */
  index: number;
  /** Mouse up event handler */
  handleMouseUp: () => void;
  /** Function to set the message feedback */
  setMsgFeedback: (feedback: 0 | 1 | -1) => void;
}) => {
  message.content = correctMessageContent(message.content);

  const articleIdToReferenceNumberMap = makeArticleIdToReferenceNumberMap(
    message.referenceData ?? [],
    message.content
  );

  const chunkToArticleReferenceNumberMap = makeChunkToArticleReferenceNumberMap(
    message.referenceData,
    articleIdToReferenceNumberMap
  );

  let chunkInfo: ChunkInfo[] = [];
  if (message.referenceData) {
    chunkInfo = message.referenceData.map((chunkData) => {
      return {
        articleId: chunkData.articleId,
        articleTitle: chunkData.articleTitle,
        articleUrl: chunkData.articleUrl,
        chunkId: chunkData.listPrefix, // do I need it?
        content: chunkData.content,
        image: false,
        referenceString: chunkData.referenceId,
      };
    });
  }

  const renderers = {
    a: (props) => (
      <ReferenceBubble
        {...props}
        chunkInfo={chunkInfo}
        chunkToArticleReferenceNumberMap={chunkToArticleReferenceNumberMap}
      />
    ),
  };

  const [isCopied, setIsCopied] = React.useState(false);

  return (
    <MessageWrapper
      chatRole={message.role}
      onMouseUp={message.role === "assistant" ? handleMouseUp : undefined}
      key={index}
    >
      <Box>
        <MessageToRender msg={message} renderers={renderers} />
        {Object.keys(articleIdToReferenceNumberMap).length > 0 && (
          <CitationChips
            referenceData={message.referenceData}
            articleIdToReferenceNumberMap={articleIdToReferenceNumberMap}
          />
        )}
      </Box>
      {message.role === "assistant" && !message.disableFeedback && (
        <HStack mt={3} ml={4} gap={4}>
          <MessageFeedback message={message} setMsgFeedback={setMsgFeedback} />
          <CopyMessage
            message={message}
            chunkToArticleReferenceNumberMap={chunkToArticleReferenceNumberMap}
            articleIdToReferenceNumberMap={articleIdToReferenceNumberMap}
            setIsCopied={setIsCopied}
            isCopied={isCopied}
          />
        </HStack>
      )}
    </MessageWrapper>
  );
};

// wrapping the Message component with the withSaveSelectionToInsights
// to enable the save selection to insights functionality
export default withSaveSelectionToInsights(Message, "Saved from Assistant");
