import { Divider, HStack } from "@chakra-ui/react";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useAssistantContext } from "../../Context/AssistantContext";
import { ViewMode } from "../../data/AssistantData";
import TemplateIconComponent from "../Icons/TemplateIconComponent";
import HeaderTab from "../SavedContents/HeaderTab";
import { getCustomizationData } from "../Utils/customizationUtils";
import AssistantHeaderWrapper from "./AssistantHeaderWrapper";

const customization = getCustomizationData();

const AssistantChatHeaderTemplateProminent = () => {
  const {
    viewMode,
    setViewMode,
    setSubscribeToConversationDoc,
    setMessages,
    setConversationId,
  } = useAssistantContext();

  return (
    <AssistantHeaderWrapper
      backButtonHandler={() => setViewMode(ViewMode.CHAT)}
    >
      <HStack gap={"16px"} w="100%" justify={"flex-end"} pr="16px">
        <HeaderTab
          icon={TemplateIconComponent}
          title={customization.template.namePlural}
          boxSize={5}
          fontSize="xs"
          handler={() => {
            setViewMode(ViewMode.TEMPLATE);
            setSubscribeToConversationDoc(false);
          }}
          infoTooltipType="briefs"
          color="rgba(8, 178, 227, 1)"
        />
        <Divider
          orientation="vertical"
          bg="rgba(0,0,0,0.12)"
          h="18px"
          w={"1px"}
        />
        {/* {process.env.REACT_APP_NEW_CUSTOM_PROMPTS_FLOW === "enable" && (
          <HeaderTab
            handler={() => {
              setViewMode(ViewMode.CUSTOMAI);
            }}
            // icon={HeaderBoxMenuIcon}
            title={"AI Modules"}
            boxSize={5}
            fontSize="xs"
            iconMt={1}
            color="#474959"
            infoTooltipType={"aiModules"}
          />
        )} */}
        <HeaderTab
          handler={() => {
            setConversationId(uuidv4());
            setMessages([]);
            setViewMode(ViewMode.CHAT);
            setSubscribeToConversationDoc(false);
            console.log("New chat");
          }}
          // icon={MdAdd}
          title={"New Chat"}
          boxSize={5}
          fontSize="xs"
        />
        {viewMode === ViewMode.CHAT && (
          <HeaderTab
            handler={() => {
              setViewMode(ViewMode.HISTORY);
            }}
            // icon={RiHistoryLine}
            title={"History"}
            boxSize={5}
            fontSize="xs"
            infoTooltipType="history"
          />
        )}
      </HStack>
    </AssistantHeaderWrapper>
  );
};

export default AssistantChatHeaderTemplateProminent;
