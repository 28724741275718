import { User } from "firebase/auth";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  FirestoreDataConverter,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { COL_CONVERSATIONS } from "shared/constants";
import {
  AssistantChatRequestDoc,
  ConversationDoc,
  MessageData,
  TableChatRequestDoc,
} from "shared/conversation";
import { ProjectDoc } from "shared/projectTypes";
import { db } from "src/firebase";
import { v4 } from "uuid";
import { getProjectData, projectsCollection } from "./project";

const conversationConverter: FirestoreDataConverter<ConversationDoc> = {
  toFirestore: (conversation: ConversationDoc) => {
    const { uid, ...conversationWithoutUid } = conversation;
    return conversationWithoutUid;
  },
  fromFirestore: (snapshot) => {
    // for compatibility with old data, for each message that does
    // not have a "type" field, add it with the value "chat"
    // TODO This can be removed if we run a migration to add the type field
    // to all messages in the database
    const data = snapshot.data() as Omit<ConversationDoc, "messages"> & {
      messages: Array<
        Omit<MessageData, "type" | "moduleTitle"> & {
          type?: string;
          moduleTitle?: string;
          title?: string;
        }
      >;
    };
    data.messages.forEach((message) => {
      if (!message.type) {
        message.type = "chat";
      }
    });

    // for compatibility with old data, for each message which has type == "custom" or "aiModuleInvocation"
    // and doesn't have a field "moduleTitle", set it equal to "title";
    // we also convert "custom" to "aiModuleInvocation" for compatibility with old data
    data.messages.forEach((message) => {
      if (message.type === "custom" || message.type === "aiModuleInvocation") {
        message.type = "aiModuleInvocation";
        if (!message.moduleTitle) {
          message.moduleTitle = message.title;
        }
      }
    });
    return {
      ...data,
      uid: snapshot.id,
      messages: data.messages as MessageData[],
    };
  },
};

export const getConversationCollection = (projectId: string) =>
  collection(
    doc(projectsCollection, projectId),
    COL_CONVERSATIONS
  ).withConverter(conversationConverter);

const tableChatRequestConverter: FirestoreDataConverter<TableChatRequestDoc> = {
  toFirestore: (tableChatRequest: TableChatRequestDoc) => tableChatRequest,
  fromFirestore: (snapshot) => snapshot.data() as TableChatRequestDoc,
};

export const tableChatRequestCollection = collection(
  db,
  "tableChatRequest"
).withConverter(tableChatRequestConverter);

export const initiateAssitantResponseOnTable = async (
  projectId: ProjectDoc["uid"],
  conversationId: ConversationDoc["uid"],
  userId: User["uid"]
) => {
  await addDoc(tableChatRequestCollection, {
    projectId,
    conversationId,
    userId,
  });
};

export const updateMsgFeedback = async ({
  projectId,
  conversationId,
  msgIndex,
  feedbackValue,
}: {
  projectId: ProjectDoc["uid"];
  conversationId: ConversationDoc["uid"];
  msgIndex: number;
  feedbackValue: -1 | 0 | 1;
}) => {
  const conversationDocRef = doc(
    getConversationCollection(projectId),
    conversationId
  );
  await updateDoc(conversationDocRef, {
    [`messageFeedback.${msgIndex}`]: feedbackValue,
  });
};

/**
 * Adds a message to the conversation.
 */
export async function addConversationMessage({
  projectId,
  conversationId,
  userId,
  message,
}: {
  projectId: ProjectDoc["uid"];
  conversationId: ConversationDoc["uid"];
  userId: User["uid"];
  message: MessageData;
}) {
  const conversationDocRef = doc(
    getConversationCollection(projectId),
    conversationId
  );
  await setDoc(
    conversationDocRef,
    {
      messages: arrayUnion(message),
      userId,
      continue: true,
    },
    { merge: true }
  );
}

const assistantChatRequestConverter: FirestoreDataConverter<AssistantChatRequestDoc> =
  {
    toFirestore: (assistantChatRequest: AssistantChatRequestDoc) =>
      assistantChatRequest,
    fromFirestore: (snapshot) => snapshot.data() as AssistantChatRequestDoc,
  };

export const assistantChatRequestCollection = collection(
  db,
  "assistantChatRequest"
).withConverter(assistantChatRequestConverter);

export const handleStopGeneration = async ({
  projectId,
  conversationId,
}: {
  projectId: ProjectDoc["uid"];
  conversationId: ConversationDoc["uid"];
}) => {
  await updateDoc(doc(getConversationCollection(projectId), conversationId), {
    continue: false,
  });
};

export async function initiateAssitantResponse(
  projectId: string,
  conversationId: string,
  currentUser: User
) {
  const projectData = await getProjectData(projectId);
  await setDoc(doc(assistantChatRequestCollection, v4()), {
    projectId,
    conversationId,
    userId: currentUser.uid,
    isSampleProject: projectData?.isSampleProject || false,
  });
}
