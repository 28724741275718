import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  OAuthProvider,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  collectionGroup,
  connectFirestoreEmulator,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  or,
  orderBy,
  query as q,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_MESSAGING_APP_ID,
};

export const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// if REACT_APP_CONNECT_TO_EMULATOR=1, connect to the emulator
if (process.env.REACT_APP_CONNECT_TO_EMULATOR === "1") {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
  connectStorageEmulator(storage, "127.0.0.1", 9199);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
} else {
  getAnalytics(app);
}

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
appleProvider.addScope("email");
appleProvider.addScope("name");
const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
  } catch (err) {
    console.error(err);
    throw err; // if you want to propagate the error upwards
  }
  return auth.currentUser;
};

const signInWithApple = async () => {
  try {
    await signInWithPopup(auth, appleProvider);
  } catch (err) {
    console.error(err);
    throw err;
  }
  return auth.currentUser;
};

const registerWithEmailAndPassword = async ({
  firstName,
  lastName,
  email,
  password,
}) => {
  const res = await createUserWithEmailAndPassword(auth, email, password);
  console.log("res", res);
  const user = res.user;

  // await sendEmailVerification(user);

  await updateProfile(user, {
    displayName: `${firstName} ${lastName}`,
  });

  return user;
};

export {
  addDoc,
  arrayRemove,
  arrayUnion,
  auth,
  collection,
  collectionGroup,
  db,
  deleteDoc,
  doc,
  fetchSignInMethodsForEmail,
  functions,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  or,
  orderBy,
  q,
  registerWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  setDoc,
  signInWithApple,
  signInWithGoogle,
  storage,
  updateDoc,
  where,
  writeBatch,
};
