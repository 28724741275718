import { WidgetData, WidgetType } from "../../../functions/shared/widgets";
import PlainTextWidget from "./PlainTextWidget";
import SearchResultsWidget from "./SearchResultsWidget";

// Generic type for widget component props
export type WidgetComponentProps<T extends WidgetData> = {
  data: T;
};

// Type for a widget component that takes specific config
type WidgetComponent<T extends WidgetData> = React.ComponentType<
  WidgetComponentProps<T>
>;

// Registry with proper typing
const WidgetRegistry: {
  [K in WidgetType]: WidgetComponent<Extract<WidgetData, { widgetType: K }>>;
} = {
  [WidgetType.PLAIN_TEXT]: PlainTextWidget,
  [WidgetType.SEARCH_RESULTS]: SearchResultsWidget,
} as const;

export function getWidgetComponent<T extends WidgetType>(
  widgetType: T
): WidgetComponent<Extract<WidgetData, { widgetType: T }>> {
  return WidgetRegistry[widgetType];
}
