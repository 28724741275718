import {
  Flex,
  GridItem,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdOutlineIndeterminateCheckBox,
} from "react-icons/md";
import { useHistory } from "react-router-dom/";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ArticleDoc } from "shared/articleTypes";
import { useProjectContext } from "src/Context/ProjectContext";
import { useArticle } from "../../../Context/ArticleContext";
import { addArticleComment, deleteArticleComment } from "../../../db/comment";
import CommentModal from "../../Elements/CommentModal";
import { getCustomizationData } from "../../Utils/customizationUtils";
import { getTableProps, IndividualTableRow } from "./IndividualTableRow";
import SelectControlButton from "./SelectArticlesControlBttn";
import { TableHead } from "./TableHead";

const customization = getCustomizationData();

export const AllArticleTable = ({
  filteredArticles,
  display,
}: {
  filteredArticles: ArticleDoc[];
  display: string;
}) => {
  const history = useHistory();
  const currentSearchParams = new URLSearchParams(location.search);
  const sortByParams = currentSearchParams.get("sortBy") || "";
  const poppedArticle = currentSearchParams.get("poppedArticle");
  const [showArticleCommentModal, setShowArticleCommentModal] = useState(false);
  const { projectId } = useParams();
  const [currentArticleId, setCurrentArticleId] = useState<string | null>(null);
  const [searchedArticle, setSearchedArticle] = useState<ArticleDoc[]>([]);
  const { currentProjectData } = useProjectContext();
  const isSharedProject = currentProjectData?.data?.shared;
  const findArticleById = () => {
    const article = filteredArticles?.find(
      (article) => article.uid === currentArticleId
    );
    return article;
  };

  const { selectedArticleIds, clearSelectedArticles, setSelectedArticleIds } =
    useArticle();

  const addAllArticleToSelectedArticleContext = () => {
    const articleIds = filteredArticles.map((article) => article.uid);
    setSelectedArticleIds(articleIds);
  };

  useEffect(() => {
    if (filteredArticles) {
      if (poppedArticle) {
        const article = filteredArticles?.find(
          (article) => article?.uid === poppedArticle
        );
        if (article) {
          setSearchedArticle([article]);
        }
      } else {
        setSearchedArticle(filteredArticles);
      }
    }
  }, [poppedArticle, filteredArticles]);

  const MENU_ITEMS = [
    { name: "All", onClick: () => addAllArticleToSelectedArticleContext() },
    {
      name: "None",
      onClick: () => clearSelectedArticles(),
    },
    {
      name: "Starred",
      onClick: () => {
        const starredArticles = filteredArticles.filter(
          (article) => article.starred
        );
        const articleIds = starredArticles.map((article) => article.uid);
        setSelectedArticleIds(articleIds);
      },
    },
    {
      name: "Unstarred",
      onClick: () => {
        const unstarredArticles = filteredArticles.filter(
          (article) => !article.starred
        );
        const articleIds = unstarredArticles.map((article) => article.uid);
        setSelectedArticleIds(articleIds);
      },
    },
  ];

  return (
    <Flex mt={6} display={display}>
      <TableContainer overflow="hidden">
        <Table size="sm" variant="unstyled" w="100%">
          <Thead>
            <Tr
              display="grid"
              gridTemplateColumns={`repeat(${
                getTableProps(isSharedProject).gridSize
              }, 1fr)`}
            >
              <GridItem
                userSelect="none"
                pb="10px"
                as={Th}
                colSpan={getTableProps(isSharedProject).checkBoxAndStarred}
                overflow="hidden"
                pl="0"
                pr={`${
                  getTableProps(isSharedProject).checkBoxAndStarredPR - 3
                }px`}
                w={getTableProps(isSharedProject).checkBoxAndStarredWidth}
              >
                <Flex
                  minW="2%"
                  justify="center"
                  display={selectedArticleIds.length > 0 ? "flex" : "none"}
                >
                  {selectedArticleIds.length > 0 && (
                    <SelectControlButton
                      icon={{ type: MdOutlineIndeterminateCheckBox }}
                      color="primary"
                      onClick={() => {
                        clearSelectedArticles();
                      }}
                      items={MENU_ITEMS}
                    />
                  )}
                </Flex>
                <Flex
                  minW="2%"
                  justify="center"
                  display={selectedArticleIds.length === 0 ? "flex" : "none"}
                >
                  {selectedArticleIds.length === filteredArticles.length &&
                  filteredArticles.length > 0 ? (
                    <SelectControlButton
                      icon={{ type: MdCheckBox }}
                      color="#727272"
                      onClick={() => {
                        clearSelectedArticles();
                      }}
                      items={MENU_ITEMS}
                    />
                  ) : (
                    <SelectControlButton
                      icon={{ type: MdCheckBoxOutlineBlank }}
                      color="#727272"
                      onClick={() => {
                        addAllArticleToSelectedArticleContext();
                      }}
                      items={MENU_ITEMS}
                    />
                  )}
                </Flex>
              </GridItem>
              <TableHead
                notSortAble={false}
                text="Name"
                history={history}
                colSpan={getTableProps(isSharedProject).title}
                isSelected={sortByParams.includes("name")}
              />
              {customization.swapTypeForLength ? (
                <TableHead
                  notSortAble={false}
                  text="Length"
                  history={history}
                  colSpan={getTableProps(isSharedProject).type}
                  isSelected={sortByParams.includes("length")}
                  gridItemProps={{
                    minW: getTableProps(isSharedProject).typeMinWidth,
                  }}
                />
              ) : (
                <TableHead
                  notSortAble={false}
                  text="Type"
                  history={history}
                  colSpan={getTableProps(isSharedProject).type}
                  isSelected={sortByParams.includes("type")}
                  gridItemProps={{
                    minW: getTableProps(isSharedProject).typeMinWidth,
                  }}
                />
              )}

              {customization.hasSourceColumn && (
                <TableHead
                  text="Source"
                  notSortAble={false}
                  history={history}
                  colSpan={getTableProps(isSharedProject).source}
                  isSelected={sortByParams.includes("source")}
                  gridItemProps={{
                    minW: getTableProps(isSharedProject).sourceMinWidth,
                  }}
                />
              )}
              {isSharedProject && (
                <TableHead
                  text="Added by"
                  notSortAble={false}
                  history={history}
                  colSpan={getTableProps(isSharedProject).addedBy}
                  isSelected={sortByParams.includes("added by")}
                  gridItemProps={{
                    minW: getTableProps(isSharedProject).addedByMinWidth,
                  }}
                />
              )}
              <TableHead
                text="Date Added"
                notSortAble={false}
                history={history}
                colSpan={getTableProps(isSharedProject).dateAdded}
                isSelected={sortByParams.includes("date")}
                gridItemProps={{
                  minW: getTableProps(isSharedProject).dateAddedMinWidth,
                }}
              />
              {/* {customization.uniteAiInsightsAndViewer ? ( */}
              <GridItem
                userSelect="none"
                pb="10px"
                as={Th}
                colSpan={getTableProps(isSharedProject).actions}
                overflow="hidden"
                pl="0"
                pr="0"
                minW={getTableProps(isSharedProject).actionsMinWidth}
              />
              {/* ) : (
                <TableHead
                  text="Insights"
                  notSortAble
                  colSpan={TABLE_PROPS.actions}
                  gridItemProps={{ minW: TABLE_PROPS.actionsMinWidth }}
                />
              )} */}
            </Tr>
          </Thead>
          <Tbody>
            {searchedArticle?.map((currentArticleData, index) => {
              // Determine if the current item is the last item of its type.
              // Used to create a larger gaps between different types of articles
              // when sorting by type.
              const isLastItem =
                currentArticleData?.type !== searchedArticle[index + 1]?.type;
              return (
                <React.Fragment key={currentArticleData.uid}>
                  <IndividualTableRow
                    articleData={currentArticleData}
                    showCommentModalAndUpdateProjectTitle={() => {
                      setShowArticleCommentModal(true);
                      setCurrentArticleId(currentArticleData.uid);
                    }}
                    isLastItem={isLastItem && sortByParams?.includes("type")}
                  />
                </React.Fragment>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      {showArticleCommentModal && (
        <CommentModal
          close={() => {
            setShowArticleCommentModal(false);
            setCurrentArticleId(null);
          }}
          isOpen={showArticleCommentModal}
          content={findArticleById()}
          createFunc={addArticleComment}
          deleteFunc={deleteArticleComment}
          rootId={projectId}
        />
      )}
    </Flex>
  );
};
