import { Flex, IconButton, Tooltip } from "@chakra-ui/react";
import React from "react";
import { MdArrowBackIosNew, MdClose } from "react-icons/md";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../Auth/AuthProvider";
import { useAssistantContext } from "../../Context/AssistantContext";
import { ViewMode } from "../../data/AssistantData";
import HeaderTab from "../SavedContents/HeaderTab";
import AssistantCreditInfoComponent from "./AssistantCreditInfoComponent";
import { FullScreenBttn } from "./FullScreenAssistant/FullScreenBttn";

const AssistantHeaderWrapper = ({
  backButtonHandler,
  title = "Back",
  children,
}: {
  backButtonHandler: () => void;
  title?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  const { setAssistantIsOpen, viewMode, assistantIsFullScreen } =
    useAssistantContext();
  const { isPowerUser } = useAuth();

  const showCreditInfo = viewMode === ViewMode.CHAT;
  return (
    <Flex py="1" position="relative">
      {viewMode !== ViewMode.CHAT && (
        <Flex align="center">
          <HeaderTab
            handler={backButtonHandler}
            boxSize={8}
            icon={MdArrowBackIosNew}
            title={title}
            fontSize="xs"
          />
        </Flex>
      )}
      {showCreditInfo && (
        <Flex flex={2} gap="2">
          <AssistantCreditInfoComponent />
          {isPowerUser && <FullScreenBttn expanded={assistantIsFullScreen} />}
        </Flex>
      )}
      {/* {viewMode !== ViewMode.TEMPLATE && <Spacer />} */}
      {/* <Spacer /> */}
      <Flex flex={5} justify={"flex-end"} align={"center"}>
        {children}
      </Flex>
      <CloseAssistant
        setAssistantIsOpen={setAssistantIsOpen}
        assistantIsFullScreen={assistantIsFullScreen}
      />
    </Flex>
  );
};

export default AssistantHeaderWrapper;

const CloseAssistant = ({ setAssistantIsOpen, assistantIsFullScreen }) => {
  const history = useHistory();
  const { projectId } = useParams();
  const goBackToProject = () => {
    history.push(`/${projectId}`);
  };
  return (
    <Tooltip
      label={
        assistantIsFullScreen
          ? "Close full screen assistant"
          : "Close assistant (⌘K)"
      }
      placement="left"
      openDelay={200}
      hasArrow
    >
      <IconButton
        aria-label="Close assistant"
        icon={<MdClose />}
        fontSize={20}
        color="black"
        onClick={() => {
          assistantIsFullScreen ? goBackToProject() : setAssistantIsOpen(false);
        }}
      />
    </Tooltip>
  );
};
