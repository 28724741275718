import { Box, Flex, HStack, Icon, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";
import { useArticle } from "../../Context/ArticleContext";
import { useAssistantContext } from "../../Context/AssistantContext";
import useNotification from "../../hooks/useNotification";

import { addDoc, collection } from "firebase/firestore";
import { IoSettingsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { manageSelectedArticles } from "../assistant/AssistantChatWindow";
import { AiModulesList } from "../Home/AIModules/AiModulesList";

import { CloseIcon } from "@chakra-ui/icons";
import {
  AiModuleInvocationMessageData,
  ConversationDoc,
} from "shared/conversation";
import { addConversationMessage } from "src/db/conversation";
import { EnrichedPresentation } from "src/hooks/useAiModules";
import { useCreateAiModuleRequest } from "src/hooks/useCreateAiModuleRequest";
import { QUERY_PARAMS, useQueryParam } from "src/utils/queryParams";
import { InputPopup } from "./InputPopup";

/**
 * Replaces placeholders in a string with corresponding values from a replacements object.
 * Handles both {{var}} and ${{requirements.user_input.var}} formats.
 *
 * @param {string} str - The string containing placeholders to be replaced.
 * @param {Object} replacements - An object containing key-value pairs where the key represents the placeholder and the value represents the replacement.
 * @returns {string} - The formatted string with replaced placeholders.
 */
function formatString(str, replacements) {
  // Handle ${{requirements.user_input.varName}} format with optional whitespace
  str = str.replace(
    /\${{\s*requirements\s*\.\s*user_input\s*\.\s*(\w+)\s*}}/g,
    function (_, key) {
      return replacements[key] || "";
    }
  );

  // Handle {{varName}} format with optional whitespace
  return str.replace(/{{(\s*\w+\s*)}}/g, function (_, key) {
    return replacements[key.trim()] || "";
  });
}

export function clearVariables(str) {
  // Clear both variable formats with optional whitespace
  return str
    .replace(/\${{\s*requirements\s*\.\s*user_input\s*\.\s*\w+\s*}}/g, "")
    .replace(/{{(\s*\w+\s*)}}/g, "");
}

const Header = () => {
  const [, setModulesDrawer] = useQueryParam(QUERY_PARAMS.modulesDrawer);
  return (
    <Flex
      direction={"column"}
      rounded="md"
      py={"16px"}
      px="8px"
      bg="#FAFAFA"
      // className="webkit-scrollbar-display-none"
    >
      <Flex flexDir="column" pos="relative">
        <HStack gap="16px" alignItems="center" justify="space-between">
          <Text fontSize="12px" fontWeight="bold" color="#1a1c30">
            Modules
          </Text>
          <HStack gap="16px" alignItems="center">
            <Flex
              as={Link}
              to="/user?tab=custom-ai"
              gap="7px"
              alignItems="center"
            >
              <IoSettingsOutline fontSize="14px" />
              <Text fontSize="12px" fontWeight="bold" color="#1a1c30">
                Library
              </Text>
            </Flex>
            <Flex>
              <Icon
                boxSize="9px"
                color="black"
                opacity="0.54"
                as={CloseIcon}
                onClick={() => setModulesDrawer(false)}
                cursor="pointer"
              />
            </Flex>
          </HStack>
        </HStack>
      </Flex>
    </Flex>
  );
};

const AiModulesDrawer = () => {
  const { mutateAsync: createPromptRequest, error } =
    useCreateAiModuleRequest();
  const { notify } = useNotification();
  const {
    conversationId,
    setConversationId,
    setSubscribeToConversationDoc,
    setViewMode,
  } = useAssistantContext();
  const { currentUser } = useAuth();
  const { projectId } = useParams();
  const { selectedArticleIds } = useArticle();
  const { allUnfilteredArticles: articles } = useArticle();

  const [inputPopupValue, setInputPopupValue] = React.useState("");
  const [variableData, setVariableData] = React.useState({
    name: "",
    optional: false,
  });
  const [variableKey, setVariableKey] = React.useState("");
  const [prompt, setPrompt] = React.useState<EnrichedPresentation | undefined>(
    undefined
  );
  const {
    isOpen: isOpenInputPopup,
    onOpen: openInputPopup,
    onClose,
  } = useDisclosure();
  const [, setModulesDrawer] = useQueryParam(QUERY_PARAMS.modulesDrawer);

  if (articles === undefined) {
    return null;
  }

  if (error) {
    notify({
      title: "Error",
      description: "Failed to start. Please try again later.",
      status: "error",
    });
    console.error(error);
  }

  const sendCustomPrompt = async ({
    module,
    conversationId,
    variableValueMap,
  }: {
    module: EnrichedPresentation;
    conversationId: ConversationDoc["uid"];
    variableValueMap?: { variableKey: string; value: string };
  }) => {
    let message: AiModuleInvocationMessageData;
    // if there is an input value
    if (variableValueMap) {
      const { variableKey, value } = variableValueMap;
      message = {
        role: "user",
        content: formatString(module.userMessage, {
          [variableKey]: value,
        }),
        createdAt: Date.now(),
        moduleTitle: module.title,
        type: "aiModuleInvocation",
        inputValue: formatString(module.userMessage, {
          [variableKey]: value,
        }),
      };
      // setInputPopupValue("");
    } else {
      message = {
        role: "user",
        content: clearVariables(module.userMessage),
        createdAt: Date.now(),
        moduleTitle: module.title,
        type: "aiModuleInvocation",
        inputValue: clearVariables(module.userMessage),
      };
    }
    // TODO: logic for selecting articles is scattered across the app;
    // refactor to a handle in a single place.

    if (selectedArticleIds.length === 0) {
      message = manageSelectedArticles(message, articles);
    } else {
      const selectedArticles = articles.filter((article) =>
        selectedArticleIds.includes(article.uid)
      );
      message.selectedArticles = selectedArticles.map((article) => ({
        uid: article.uid,
        title: article.title,
        type: article.type,
      }));
    }

    await addConversationMessage({
      projectId,
      conversationId,
      userId: currentUser.uid,
      message,
    });

    if (module.api_version === 1) {
      const invocationPayload = {
        userId: currentUser.uid,
        projectId,
        conversationId,
        aiModuleId: module.uid,
        variables: {
          requirements: {
            client_input: {
              $project_id: projectId,
              $conversation_id: conversationId,
              $selected_articles: message.selectedArticles,
            },
            user_input: {},
          },
        },
      };
      if (variableValueMap) {
        const { variableKey, value } = variableValueMap;
        if (value) {
          invocationPayload.variables.requirements.user_input[variableKey] =
            value;
        } else if (variableData.optional) {
          invocationPayload.variables.requirements.user_input[variableKey] = "";
        } else {
          notify({
            title: "Error",
            description: `Value for ${variableData.name.toLowerCase()} is required.`,
            status: "error",
          });
          return;
        }
      }
      addDoc(collection(db, "aiModuleInvocations"), invocationPayload);
    } else {
      await createPromptRequest({
        module: module,
        conversationId,
        projectId,
      });
    }

    setConversationId(conversationId);
    setSubscribeToConversationDoc(true);
    setViewMode("CHAT");
  };

  async function handleStartCustomPrompt(prompt: EnrichedPresentation) {
    setPrompt(prompt);
    if (prompt.variables && Object.keys(prompt.variables).length === 0) {
      await sendCustomPrompt({ module: prompt, conversationId });
      setModulesDrawer(false);
      return;
    }
    if (prompt.variables) {
      const variableKeys = Object.keys(prompt.variables);
      if (variableKeys.length === 0) {
        // no variables, just exit the if block
        return;
      } else if (variableKeys.length > 1) {
        // check if there are more than one variable in `variables` object
        // -- not supported for now
        notify({
          title: "Error",
          description: "Multiple variables are not supported.",
          status: "error",
        });
        return;
      } else {
        // if there is a single variable, open the input popup;
        // it will handle the input value and send the prompt
        const variableKey = variableKeys[0];
        const variable = prompt.variables[variableKey];
        if (typeof variable.optional === "boolean") {
          setPrompt(prompt);
          setVariableKey(variableKey);
          setVariableData({
            name: variable.description,
            optional: variable.optional,
          });
          openInputPopup();
          return;
        }
      }
    }
  }

  const handlePopUpAction = async () => {
    if (!prompt) {
      throw new Error("No prompt selected");
    }
    if (!inputPopupValue && !variableData.optional) {
      notify({
        title: "Error",
        description: `Value for ${variableData.name.toLowerCase()} is required.`,
        status: "error",
      });
      return;
    }
    await sendCustomPrompt({
      module: prompt,
      variableValueMap: {
        variableKey,
        value: inputPopupValue,
      },
      conversationId,
    });
    setModulesDrawer(false);
    onClose();
    setVariableKey("");
    setVariableData({ name: "", optional: false });
    setPrompt(undefined);
    setInputPopupValue("");
  };

  const handleCloseInputPopup = () => {
    setVariableKey("");
    setVariableData({ name: "", optional: false });
    setPrompt(undefined);
    setInputPopupValue("");
    setInputPopupValue("");
    onClose();
  };

  return (
    <Box w="full" position="relative" h="full">
      <InputPopup
        isOpen={isOpenInputPopup}
        onClose={handleCloseInputPopup}
        action={handlePopUpAction}
        input={inputPopupValue}
        setInput={setInputPopupValue}
        body={`Please enter ${
          variableData.name?.charAt(0).toLowerCase() +
          variableData.name?.slice(1)
        }:`}
        optional={variableData.optional}
      />
      <Flex direction="column" h="full">
        <Header />
        <AiModulesList
          action="SEND"
          onClick={async (prompt) => {
            await handleStartCustomPrompt(prompt);
          }}
        />
      </Flex>
    </Box>
  );
};

export default AiModulesDrawer;
