import { Link, Skeleton, Text } from "@chakra-ui/react";
import React from "react";
import {
  useActiveWorkspaceId,
  useActiveWorkspaceResources,
} from "src/stores/userWorkspaces";
import { getCustomizationData } from "../Utils/customizationUtils";
import { useWorkspacePermissions } from "./MemberTable";

const customization = getCustomizationData();

export const ShowActiveMembers = () => {
  const { workspaceMembersLimit } = useActiveWorkspaceResources();
  const activeWorkspaceId = useActiveWorkspaceId();
  const {
    data: workspacePermissionsData,
    isLoading: isLoadingWorkspacePermissions,
  } = useWorkspacePermissions(activeWorkspaceId);
  if (isLoadingWorkspacePermissions || !workspacePermissionsData)
    return <Skeleton height="10px" width="50px" />;

  const activeMembersCount = workspacePermissionsData?.length;

  const remainingSeats = workspaceMembersLimit - activeMembersCount;
  const showWarning = remainingSeats < 1;

  const UpgradeLink = () => {
    return (
      <Text display="inline-block">
        Seats used, get in touch to{" "}
        <Link
          fontWeight="900"
          href={customization.bookMeetingUrl}
          color="#08B2E3"
          target="_blank"
        >
          upgrade now
        </Link>
        .
      </Text>
    );
  };

  const GetPromptMessage = () => {
    if (remainingSeats > 1 || !customization.bookMeetingUrl)
      return <Text display="inline-block">Seats used</Text>;
    return <UpgradeLink />;
  };

  return (
    <Text fontSize="12px" color="#727272">
      <Text
        display="inline-block"
        fontWeight="900"
        color={showWarning ? "#FE2020" : "#727272"}
      >
        {activeMembersCount}/{workspaceMembersLimit}
      </Text>{" "}
      <GetPromptMessage />
    </Text>
  );
};
