import { Box, Flex } from "@chakra-ui/react";
import { User } from "firebase/auth";
import React, { useRef } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ArticleDoc } from "shared/articleTypes";
import {
  BaseChatMessageData,
  SimpleChatMessageData,
} from "shared/conversation";
import {
  addConversationMessage,
  handleStopGeneration,
  initiateAssitantResponse,
  initiateAssitantResponseOnTable,
} from "src/db/conversation";
import { useScrollToRef } from "src/hooks/useScrollToRef";
import { useActiveWorkspaceResources } from "src/stores/userWorkspaces";
import { QUERY_PARAMS, useQueryParam } from "src/utils/queryParams";
import { useAuth } from "../../Auth/AuthProvider";
import { useArticle } from "../../Context/ArticleContext";
import {
  MessageForRendering,
  useAssistantContext,
} from "../../Context/AssistantContext";
import { ARTICLE_TYPE_DATA } from "../../data/articleTypeData";
import useNotification from "../../hooks/useNotification";
import AiModulesDrawer from "../AiModules/AiModulesDrawer";
import { AIChatTour } from "../Tour/AIChatTour";
import AssistantChatInput from "./AssistantChatInput";
import { ChatControls } from "./ChatControls";
import { Messages } from "./Messages";

const sortMessages = (messages: MessageForRendering[]) => {
  return messages.sort((a, b) => a.createdAt - b.createdAt);
};

const handleSendMessage = async ({
  messages,
  message,
  setMessages,
  articles,
  projectId,
  conversationId,
  setSubscribeToConversationDoc,
  currentUser,
}: {
  messages: MessageForRendering[];
  message: SimpleChatMessageData;
  setMessages: (messages: MessageForRendering[]) => void;
  articles: ArticleDoc[];
  projectId: string;
  conversationId: string;
  setSubscribeToConversationDoc: (subscribe: boolean) => void;
  currentUser: User;
}) => {
  setMessages([...messages, message]);

  message = manageSelectedArticles(message, articles);

  await addConversationMessage({
    projectId,
    conversationId,
    userId: currentUser.uid,
    message,
  });
  // set the converation as started (this will trigger subscription to the doc)
  setSubscribeToConversationDoc(true);

  await initiateChatResponse(message, projectId, conversationId, currentUser);
};

const AssistantChatWindow = () => {
  const { projectId } = useParams();
  const { currentUser } = useAuth();
  const {
    conversationId,
    messages,
    setMessages,
    conversationData,
    subscribeToConversationDoc,
    setSubscribeToConversationDoc,
    assistantIsFullScreen,
    useOnlyChat,
  } = useAssistantContext();
  const { allUnfilteredArticles: articles } = useArticle();
  const { notify } = useNotification();
  const [modulesDrawer] = useQueryParam(QUERY_PARAMS.modulesDrawer);

  const sortedMessages = sortMessages(messages);

  const onSendMessage = async (message: {
    role: SimpleChatMessageData["role"];
    content: SimpleChatMessageData["content"];
    inputValue: SimpleChatMessageData["inputValue"];
    createdAt: SimpleChatMessageData["createdAt"];
    selectedArticles: SimpleChatMessageData["selectedArticles"];
    useOnlyChat: boolean;
  }) => {
    await handleSendMessage({
      messages,
      message: {
        ...message,
        type: "chat",
      },
      setMessages,
      articles: useOnlyChat ? [] : articles || [],
      projectId,
      conversationId,
      setSubscribeToConversationDoc,
      currentUser,
    });
  };

  const assistantIsGenerating =
    subscribeToConversationDoc && conversationData?.continue
      ? conversationData.continue
      : false;

  // Ref for the text input field in the assistant chat window, used to focus the input
  // after an article is clicked (see RelatedArticles component)
  const inputRef = useRef(null);

  const { aiCreditsTotal } = useActiveWorkspaceResources();
  const assistantDisabledFromLimitations = aiCreditsTotal <= 0;

  const assistantDisabled =
    assistantDisabledFromLimitations || (assistantIsGenerating ?? false);

  // Selected articles are used to limit the assistant search to the selected articles.
  const { inputValue, setInputValue } = useAssistantContext();
  const { selectedArticlesData } = useArticle();

  const { endRef: messagesEndRef } = useScrollToRef(
    JSON.stringify(sortedMessages)
  );

  const handleSendClick = async () => {
    if (inputValue.trim() === "") {
      notify({
        title: "Error",
        description: "Please enter a message.",
        status: "error",
      });
      return;
    }

    await onSendMessage({
      role: "user",
      content: inputValue,
      inputValue: inputValue,
      createdAt: Date.now(),
      // TODO This is where we (partially) control selected articles. If there are selected articles,
      // they will be added to the message. If no articles are selected,
      // selectedArticlesData array will be empty, and later (in `handleSendMessage`)
      // we will add all articles to the message (see `manageSelectedArticles`). This is part of the
      // issue with logic for selected articles being spread around. It would be better to handle this
      // in a more consistent manner in a single place.
      selectedArticles: useOnlyChat ? [] : selectedArticlesData,
      useOnlyChat,
    });
    setInputValue("");
  };
  const [isModulesDrawerOpen] = useQueryParam(QUERY_PARAMS.modulesDrawer);

  return (
    <Box
      bg="transparent"
      w="full"
      height={assistantIsFullScreen ? "96%" : "full"}
      position="relative"
    >
      {/* pb needs to be so high here to compensate for the header */}
      <Flex
        direction="column"
        h="full"
        pb={assistantIsFullScreen ? 0 : 12}
        gap={0}
        bg="white"
      >
        <AIChatTour />
        <Box
          flex="1"
          pt={2}
          pb={5}
          pl={2}
          pr={2}
          bg="#FAFAFA"
          opacity={isModulesDrawerOpen ? 0.3 : 1}
          borderRadius="12px"
          overflowY="auto"
          className="webkit-scrollbar-display-none"
        >
          <Messages
            sortedMessages={sortedMessages}
            projectId={projectId}
            conversationId={conversationId}
            messagesEndRef={messagesEndRef}
          />
        </Box>
        {modulesDrawer && (
          <Box
            mt={2}
            bg="#FAFAFA"
            boxShadow="0px -4px 8px 0px rgb(0 0 0 / 8%)"
            borderRadius="12px"
            height="500px"
            overflow="hidden"
          >
            <Box
              height="100%"
              overflowY="auto"
              className="webkit-scrollbar-display-none"
              px={2}
            >
              <AiModulesDrawer />
            </Box>
          </Box>
        )}
        <Flex h="8px" />
        <Box position="relative">
          <ChatControls length={messages.length} />
          <AssistantChatInput
            assistantDisabled={assistantDisabled}
            handleSendClick={handleSendClick}
            assistantIsGenerating={assistantIsGenerating}
            handleStopGeneration={() =>
              handleStopGeneration({ projectId, conversationId })
            }
            inputRef={inputRef}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default AssistantChatWindow;

/**
 * If no articles are selected, add all articles to the message
 */
export const manageSelectedArticles = <T extends BaseChatMessageData>(
  message: T,
  articles: ArticleDoc[]
): T => {
  // If there is already at least one article selected, do nothing
  if (message.selectedArticles && message.selectedArticles.length >= 1) {
    return message;
  }

  // If there are no articles selected, add all project articles to the message
  message.selectedArticles = articles
    .filter((article) => ARTICLE_TYPE_DATA[article.type]?.requestFromVectorDb)
    .map((article) => {
      return {
        uid: article.uid,
        title: article.title,
        type: article.type,
      };
    });
  return message;
};

/**
 * If there is 1 selected article and it is of type table
 * then we create a request doc in tableChatRequest collection
 * and send a request to the assistant to respond to the table.
 *
 * Otherwise, we create a request-doc to continue the conversation
 * with the assistant.
 */
async function initiateChatResponse(
  message: SimpleChatMessageData,
  projectId: string,
  conversationId: string,
  currentUser: User
) {
  if (
    message.selectedArticles?.length === 1 &&
    message.selectedArticles[0].type === "table"
  ) {
    await initiateAssitantResponseOnTable(
      projectId,
      conversationId,
      currentUser.uid
    );
  } else {
    // create a request-doc to continue the conversation with the assistant
    await initiateAssitantResponse(projectId, conversationId, currentUser);
  }
}
