import React, { createContext, useContext, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { ArticleDoc } from "shared/articleTypes";
import {
  AiModuleInvocationMessageData,
  ConversationDoc,
  ReferenceData,
  SimpleChatMessageData,
  TemplateMessageData,
  WidgetMessageData,
} from "shared/conversation";
import { isSimpleChatMessage } from "shared/conversationUtils";
import { getConversationCollection } from "src/db/conversation";
import { v4 } from "uuid";
import { getCustomizationData } from "../Components/Utils/customizationUtils";
import { ViewMode } from "../data/AssistantData";
import { doc, onSnapshot } from "../firebase";
import { useArticle } from "./ArticleContext";
import {
  OrganizerContextProvider,
  useOrganizerContext,
} from "./OrganizerContext";
import { useProjectContext } from "./ProjectContext";
import { TemplateContextProvider } from "./TemplateContext";

export type ChatMessageForRendering = (
  | SimpleChatMessageData
  | AiModuleInvocationMessageData
  | TemplateMessageData
) & {
  disableFeedback?: boolean;
  continue?: boolean;
  referenceData?: EnrichedReferenceData[];
  feedback?: 0 | 1 | -1;
};

export type MessageForRendering = WidgetMessageData | ChatMessageForRendering;

const customization = getCustomizationData();

interface AssitantContextType {
  inputValue: string;
  setInputValue: (inputValue: string) => void;
  conversationId: string;
  setConversationId: (conversationId: string) => void;
  subscribeToConversationDoc: boolean;
  setSubscribeToConversationDoc: (subscribeToConversationDoc: boolean) => void;
  conversationData: ConversationDoc | null;
  setConversationData: (conversationData: ConversationDoc | null) => void;
  viewMode: ViewMode;
  setViewMode: (viewMode: ViewMode) => void;
  messages: MessageForRendering[];
  setMessages: (messages: MessageForRendering[]) => void;
  assistantIsOpen: boolean;
  setAssistantIsOpen: (assistantIsOpen: boolean) => void;
  assistantWidth: number;
  setAssistantWidth: (assistantWidth: number) => void;
  useOnlyChat: boolean;
  setUseOnlyChat: (useOnlyChat: boolean) => void;
  showUploadModal: boolean;
  setShowUploadModal: (showUploadModal: boolean) => void;
  assistantIsFullScreen: boolean;
  setAssistantIsFullScreen: (assistantIsFullScreen: boolean) => void;
}

const AssitantContext = createContext<AssitantContextType | undefined>(
  undefined
);

export type EnrichedReferenceData = ReferenceData & {
  articleTitle: string;
  articleUrl?: string;
  articleSource: string;
  articleIcon?: string;
  articleType: string;
};

const enrichReferenceDataWithArticleData = (
  referenceData: ReferenceData[],
  articles: ArticleDoc[]
) => {
  if (!referenceData) {
    return [];
  }
  const enrichedReferenceData: EnrichedReferenceData[] = [];
  referenceData.forEach((chunkData) => {
    const articleData = articles.find((a) => a.uid === chunkData.articleId);
    if (!articleData) {
      return;
    }
    enrichedReferenceData.push({
      ...chunkData,
      articleTitle: articleData.title,
      articleUrl: articleData.url,
      articleSource: articleData.source,
      articleIcon: articleData.icon,
      articleType: articleData.type,
    });
  });
  return enrichedReferenceData;
};

/**
 * Adds article data to the reference data, so that the assistant can display the
 * article title, url, etc in the references.
 *
 * @param {*} messages
 * @param {*} referenceData
 * @param {*} articles
 * @returns
 */
export const injectReferenceData = (
  messages: MessageForRendering[],
  referenceData: Map<number, ReferenceData[]>,
  articles: ArticleDoc[]
) => {
  if (!referenceData) {
    return messages;
  }

  const messagesWithReferenceData: MessageForRendering[] = messages.map(
    (message, index) => {
      const enrichedReferenceData = enrichReferenceDataWithArticleData(
        referenceData[index],
        articles
      );
      if (referenceData[index]) {
        return {
          ...message,
          referenceData: enrichedReferenceData,
        };
      } else {
        return message;
      }
    }
  );
  return messagesWithReferenceData;
};

export const injectFeedbackData = (messages, messageFeedback) => {
  if (!messageFeedback) {
    return messages;
  }
  const messagesWithFeedbackData = messages.map((message, index) => {
    if (messageFeedback[index]) {
      return {
        ...message,
        feedback: messageFeedback[index],
      };
    } else {
      return message;
    }
  });
  return messagesWithFeedbackData;
};

const DragDropWrapper = ({ children }) => {
  const { onDragEnd } = useOrganizerContext();
  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
      {children}
    </DragDropContext>
  );
};
export const AssistantContextProvider = ({ children }) => {
  const [inputValue, setInputValue] = useState("");
  const [conversationId, setConversationId] = useState("");
  // when subscribeToConversationDoc is true, it triggers subscription to the conversation doc
  // in the database
  const [subscribeToConversationDoc, setSubscribeToConversationDoc] =
    useState(false);
  const [conversationData, setConversationData] =
    useState<ConversationDoc | null>(null);
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.CHAT);
  // const [hover, setHover] = useState(true);
  const [messages, setMessages] = useState<MessageForRendering[]>([]);
  const [assistantIsOpen, setAssistantIsOpen] = useState(
    customization.assistant.isOpen
  );
  const [assistantWidth, setAssistantWidth] = useState(650);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { allUnfilteredArticles: articles } = useArticle();
  const [useOnlyChat, setUseOnlyChat] = useState(false);
  const [assistantIsFullScreen, setAssistantIsFullScreen] = useState(false);
  const { currentProjectId: projectId } = useProjectContext();

  useEffect(() => {
    // We can only subscribe to the conversation doc when the conversation has started.
    // This is because users can only access conversations with their userId (enforced by firestore.rules).
    // The conversation doc is created when first user message is sent, and this is when userId is set there.
    // For that reason we need to check if the conversation has started before subscribing to the doc,
    // otherwise we will get a database permission error.
    // Also, HISTORY mode switches conversationStarted to true, so we need to check
    // for that as well. santop 2023-09-04 Mon 17:56:14
    if (
      articles &&
      subscribeToConversationDoc &&
      viewMode !== ViewMode.HISTORY
    ) {
      if (!projectId) {
        return;
      }
      console.log("Fetching messages for conversationId: ", conversationId);
      const conversationDocRef = doc(
        getConversationCollection(projectId),
        conversationId
      );
      // Fetch messages for the given conversationId
      const unsubscribe = onSnapshot(conversationDocRef, (doc) => {
        if (!doc.exists()) {
          console.error("Empty conversation");
        } else {
          const conversationData = doc.data();
          setConversationData(conversationData);
          let conversationMessages: MessageForRendering[] =
            conversationData.messages;
          conversationMessages = injectReferenceData(
            conversationMessages,
            conversationData.referenceData,
            articles
          );
          conversationMessages = injectFeedbackData(
            conversationMessages,
            conversationData.messageFeedback
          );
          // inject "continue" flag to the last message
          // it is used for animating a loading spinner on the last message
          const lastMessage =
            conversationMessages[conversationMessages.length - 1];
          if (isSimpleChatMessage(lastMessage)) {
            lastMessage.continue = conversationData.continue;
          }
          setMessages(conversationMessages);
        }
      });
      return () => unsubscribe();
    }
  }, [conversationId, articles, subscribeToConversationDoc]);

  useEffect(() => {
    if (!conversationId) {
      const newConversationId = v4();
      setConversationId(newConversationId);
    }
  }, [conversationId]);

  useEffect(() => {
    if (messages.length === 0) {
      setMessages([
        {
          role: "assistant",
          content: customization.assistant.openingMessage,
          disableFeedback: true,
          createdAt: Date.now(),
          type: "chat",
        },
      ]);
    }
  }, [messages]);

  // if there are no messages, add the intro message

  return (
    <AssitantContext.Provider
      value={{
        inputValue,
        setInputValue,
        conversationId,
        setConversationId,
        subscribeToConversationDoc,
        setSubscribeToConversationDoc,
        conversationData,
        setConversationData,
        viewMode,
        setViewMode,
        messages,
        setMessages,
        assistantIsOpen,
        setAssistantIsOpen,
        assistantWidth,
        setAssistantWidth,
        useOnlyChat,
        setUseOnlyChat,
        showUploadModal,
        setShowUploadModal,
        assistantIsFullScreen,
        setAssistantIsFullScreen,
      }}
    >
      <TemplateContextProvider>
        <OrganizerContextProvider>
          <DragDropWrapper>{children}</DragDropWrapper>
        </OrganizerContextProvider>
      </TemplateContextProvider>
    </AssitantContext.Provider>
  );
};

export const useAssistantContext = () => {
  const context = useContext(AssitantContext);
  if (context === undefined) {
    throw new Error("context must be used within a AssistantProvider");
  }
  return context;
};

export default AssitantContext;
