import { Box } from "@chakra-ui/react";
import React, { MouseEventHandler, ReactNode } from "react";
import { MessageRole } from "shared/conversation";

interface MessageWrapperProps {
  chatRole: MessageRole;
  children: ReactNode;
  onMouseUp?: MouseEventHandler<HTMLDivElement>;
  className?: string;
}

const MESSAGE_STYLES: Record<
  string,
  {
    justifySelf: string;
    marginLeft: string;
    bg: string;
  }
> = {
  user: {
    justifySelf: "flex-end",
    marginLeft: "auto",
    bg: "#F1F1F1",
  },
  assistant: {
    justifySelf: "flex-start",
    marginLeft: "0",
    bg: "white",
  },
  error: {
    justifySelf: "center",
    marginLeft: "0",
    bg: "#FFC1C1",
  },
};

export const MessageWrapper = ({
  chatRole,
  children,
  onMouseUp,
  className,
}: MessageWrapperProps) => {
  return (
    <Box
      w="70%"
      justifySelf={MESSAGE_STYLES[chatRole].justifySelf}
      marginLeft={MESSAGE_STYLES[chatRole].marginLeft}
      onMouseUp={onMouseUp}
      pt="8px"
      className={`${chatRole}-message ${className || ""}`}
      bg={MESSAGE_STYLES[chatRole].bg}
      borderRadius="lg"
      p={2}
    >
      {children}
    </Box>
  );
};
