import { Link, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { ArticleDoc } from "shared/articleTypes";
import { ARTICLE_TYPE_DATA, TITLE_OPENS } from "../../../data/articleTypeData";

/**
 * Renders a title component with optional tooltip.
 */
const TitleComponent = ({ articleData, notInteractive }: { 
  /** The article data object containing title and uid. */
  articleData: ArticleDoc, 
  /** Flag indicating if the component should be interactive or not. */
  notInteractive?: boolean 
}) => {
  return (
    <Tooltip label={articleData.title} openDelay={300} placement="top">
      <Text
        _hover={{ textDecoration: notInteractive ? "none" : "underline" }}
        key={articleData.uid}
        fontSize="12px"
      >
        {articleData.title}
      </Text>
    </Tooltip>
  );
};

/**
 * Renders a title component with or without a link based on the article type.
 *
 * @param {Object} props - The component props.
 * @param {string} props.type - The type of the article.
 * @param {string} props.projectId - The ID of the project.
 * @param {string} props.uid - The UID of the article.
 * @param {Object} props.articleData - The article data object.
 * @param {string} props.url - The URL of the article (for external links).
 * @returns {JSX.Element} The rendered title component.
 */
export function TitleWithOrWithoutLink({
  projectId,
  uid,
  type,
  url,
  articleData,
}) {
  if (ARTICLE_TYPE_DATA[type].titleOpens === TITLE_OPENS.url) {
    return (
      <Link href={url} isExternal>
        <TitleComponent articleData={articleData} />
      </Link>
    );
  }
  return (
    <RouterLink
      to={{
        pathname: `/${projectId}/${uid}/articledetail/`,
      }}
    >
      <TitleComponent articleData={articleData} />
    </RouterLink>
  );
}
