import { Box, Button, Flex, Input, InputGroup, Text } from "@chakra-ui/react";
import md5 from "md5";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ArticleDoc } from "shared/articleTypes";
import { useAuth } from "src/Auth/AuthProvider";
import { useContentContext } from "../../Context/ContentContext";
import { ARTICLE_TYPE_DATA } from "../../data/articleTypeData";
import { createArticle } from "../../db/article";
import {
  checkIfArticleAlreadyAdded,
  getArticleSource,
} from "../Utils/articleUtil";
import { removeUndefinedNull } from "../Utils/dbUtils";
import { generateIconUrl } from "../Utils/fetchUrlIcon";

const YOUTUBE_URL_REGEX =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:[&?].*)?$/;

const isValidYoutubeUrl = (url: string) => {
  return YOUTUBE_URL_REGEX.test(url);
};

const getYoutubeVideoId = (url: string) => {
  const match = url.match(YOUTUBE_URL_REGEX);
  return match ? match[1] : null;
};

const YoutubeComponent = () => {
  const [articleUrl, setArticleLink] = useState("");
  const [linkIsUploading, setLinkIsUploading] = useState(false);
  const [linkIsValid, setLinkIsValid] = useState(false);
  const { projectId } = useParams();
  const { showToaster } = useContentContext();
  const { currentUser } = useAuth();
  const resetValues = () => {
    setLinkIsUploading(false);
    setArticleLink("");
  };

  const addArticleToProject = async ({ url }: { url: string }) => {
    const createdAt = Date.now();
    const type = "youtube";
    const createdBy = currentUser.uid;
    const source = getArticleSource(url);
    const articleId = md5(url);
    const payload: Omit<ArticleDoc, "uid"> = {
      createdAt,
      createdBy,
      source,
      type,
      indexingStatus: {
        status: ARTICLE_TYPE_DATA[type].skipIndexing ? "done" : "pending",
      },
      url,
      title: "",
    };
    removeUndefinedNull(payload);
    payload.icon = generateIconUrl(url);
    await createArticle({ projectId, articleId, payload });
    showToaster("Info", "Link successfully added to project", "success");
    resetValues();
  };

  const addLinkToProject = () => {
    checkIfArticleAlreadyAdded({ projectId, url: articleUrl }).then(
      (articleExists) => {
        if (articleExists) {
          showToaster("Error", "Link already added to project", "error");
          resetValues();
        } else {
          try {
            if (!linkIsValid || !articleUrl) return;
            setLinkIsUploading(true);
            addArticleToProject({ url: articleUrl });
          } catch (err) {
            console.log(err);
            resetValues();
            showToaster("Error", err.message, "error");
          }
        }
      }
    );
  };

  useEffect(() => {
    if (articleUrl) {
      const linkIsValid = isValidYoutubeUrl(articleUrl);
      setLinkIsValid(linkIsValid);
    }
  }, [articleUrl]);

  return (
    <Box>
      <InputGroup mt="4">
        <Input
          size="lg"
          borderColor="#282C3E"
          bg="#282C3E"
          rounded="3xl"
          _placeholder={{ fontSize: "sm" }}
          placeholder="ADD URL"
          onChange={(e) => {
            setArticleLink(e.target.value);
            if (!e.target.value) setLinkIsValid(false);
          }}
          value={articleUrl}
          fontSize="sm"
          onKeyDown={(e) => {
            if (e.key === "Enter") addLinkToProject();
          }}
        />
      </InputGroup>
      {
        <Text
          ml={2}
          color={!linkIsValid ? "red" : "green"}
          mt={1}
          fontSize="xs"
          style={{
            visibility: !articleUrl ? "hidden" : "visible",
          }}
        >
          {linkIsValid
            ? `Found Video ID: ${getYoutubeVideoId(articleUrl)}`
            : "Enter a valid Youtube URL to a video"}
        </Text>
      }
      <Flex justifyContent="flex-end">
        <Button
          rounded="2xl"
          isLoading={linkIsUploading}
          isDisabled={!linkIsValid}
          backgroundColor="#282C3E"
          w="60px"
          fontSize="sm"
          fontWeight="medium"
          onClick={addLinkToProject}
        >
          Add
        </Button>
      </Flex>
    </Box>
  );
};

export default YoutubeComponent;
