import { Flex, Switch, Text } from "@chakra-ui/react";
import React from "react";
import { useAssistantContext } from "../../Context/AssistantContext";

export const ToggleOnlyCurrentChatBttn = () => {
  const { useOnlyChat, setUseOnlyChat } = useAssistantContext();

  return (
    <Flex
      w="50%"
      align="center"
      gap="6px"
      opacity={useOnlyChat ? "1" : "0.5"}
      color="#191D30"
      // display={messages.length >= 2 ? "flex" : "none"}
    >
      <Switch
        isRequired
        sx={{
          ".chakra-switch__track": {
            bg: "transparent",
            border: "2px solid #000",
            width: "14px",
            height: "8px",
            padding: "2px",
            paddingRight: "6px",
          },
          ".chakra-switch__thumb": {
            bg: "primary",
            width: "8px",
            height: "8px",
          },
        }}
        size="sm"
        background="transparent"
        onChange={() => {
          setUseOnlyChat(!useOnlyChat);
        }}
      />
      <Text fontSize="10px" fontWeight="500">
        Restrict information to current chat answers{" "}
      </Text>
      {/* <Tooltip
        label="Restrict information to current chat answers"
        placement="top"
      >
        <Flex>
          <FaRegQuestionCircle fontSize="12px" color="black" opacity="0.5" />
        </Flex>
      </Tooltip> */}
    </Flex>
  );
};
