import React from "react";
import { MessageForRendering } from "../../Context/AssistantContext";
import { updateMsgFeedback } from "../../db/conversation";

import { Flex } from "@chakra-ui/react";
import {
  isAiModuleInvocationMessage,
  isSimpleChatMessage,
  isTemplateMessage,
  isWidgetMessage,
} from "shared/conversationUtils";
import { getWidgetComponent } from "../widgets/WidgetRegistry";
import { ChatLoadingAnimation } from "./Elements/ChatLoadingAnimation";
import _MessageComponent from "./Message/MessageCitation";

const MessageComponent = React.memo(_MessageComponent);

interface MessagesProps {
  sortedMessages: MessageForRendering[];
  projectId: string;
  conversationId: string;
  messagesEndRef: React.RefObject<HTMLDivElement>;
}

export const Messages: React.FC<MessagesProps> = ({
  sortedMessages,
  projectId,
  conversationId,
  messagesEndRef,
}) => {
  const messageComponents = sortedMessages.map((message, index) => {
    if (
      isSimpleChatMessage(message) ||
      isAiModuleInvocationMessage(message) ||
      isTemplateMessage(message)
    ) {
      return (
        <MessageComponent
          message={message}
          index={index}
          key={message.content + index}
          setMsgFeedback={(feedbackValue: 0 | 1 | -1) => {
            updateMsgFeedback({
              projectId,
              conversationId,
              msgIndex: index,
              feedbackValue,
            });
          }}
        />
      );
    }

    if (isWidgetMessage(message)) {
      const WidgetComponent = getWidgetComponent(message.data.widgetType);
      return <WidgetComponent data={message.data} key={index} />;
    }

    return null;
  });

  return (
    <Flex flexDirection="column" gap={2}>
      {messageComponents}
      <ChatLoadingAnimation messages={sortedMessages} />
      <div ref={messagesEndRef} />
    </Flex>
  );
};
